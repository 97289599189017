<template>
  <v-list-item>
    <v-list-item-avatar class="align-self-start c-list-item__avatar">
      <Avatar :user="sender" />
      <span class="c-feelingBadge">{{ _.get(gift, 'sender_feeling.emoji', defaultFeeling) }}</span>
    </v-list-item-avatar>
    <v-list-item-content class="flex-column align-start">
      <v-row
        align="center"
        class="ma-0 pa-0 flex-wrap mb-1"
      >
        <router-link
          v-if="gift.sender_id >0"
          :to="{ name: 'member/home', params: { id: gift.sender_id } }"
          class="c-sender__name"
        >
          <strong>{{ senderName }}</strong>
        </router-link>
        <strong v-else>{{ senderName }}</strong>
        <div class="timeline-date ml-2">
          {{ $d($moment(gift.created_at).toDate(), 'long') }}
        </div>
        <v-spacer />
      </v-row>
      <v-row
        align="center"
        class="ma-0 pa-0 flex-wrap mb-1"
      >
        <div>
          <template v-if="gift.point >0">
            {{ gift.point }} チップ 受け取りました。
          </template>
          <template v-else>
            感謝を受け取りました。
          </template>
        </div>
      </v-row>
      <v-row
        align="center"
        class="ma-0 pa-0 flex-wrap mb-1"
      >
        <div>
          <TagGroup :value="_.map(gift.categories, 'name')" />
        </div>
      </v-row>
      <v-row class="ma-0 pa-0 c-gift__body">
        <div
          v-if="isDeleted"
          class="c-gift__message c-gift__message--deleted flex-grow-1"
          v-html="(isMine || isAdminMode) ? message : $t('message.inappropriate')"
        />
        <div
          v-else
          :class="{ 'c-gift__message--edited': isEdited }"
          class="c-gift__message flex-grow-1"
          v-html="message"
        />
        <v-row
          align-content="start"
          class="ma-0 pa-0 flex-grow-0 flex-shrink-0 ml-auto"
        >
          <v-spacer />
          <div v-if="isMine">
            <IconButton
              :disabled="disabled"
              :icon="icons.mdiPencil"
              @click="showEditDialog"
            />
            <IconButton
              :disabled="disabled"
              :icon="icons.mdiDelete"
              @click="showDeleteDialog"
            />
          </div>
          <div v-if="!isMine && isAdminMode">
            <IconButton
              v-if="!isDeleted"
              :disabled="disabled"
              :icon="icons.mdiDelete"
              color="error"
              @click="showDeleteDialog"
            />
            <IconButton
              v-else
              :disabled="disabled"
              :icon="icons.mdiDeleteRestore"
              color="error"
              @click="showRestoreDialog"
            />
          </div>
        </v-row>
      </v-row>
      <v-row
        align="center"
        class="ma-0 pa-0 flex-wrap mb-1"
      >
        <ReactionChipGroup
          :loading="reactionLoading"
          :value="reactions"
          @click:reaction="selectReaction"
        />
      </v-row>
      <v-row
        v-if="gift.comments.length >0"
        class="ma-0 pa-0 mt-2"
      >
        <v-col>
          {{ gift.comments.length }}件の返信があります。
          <a
            v-if="commentable"
            href="#"
            @click.stop.prevent="moveToThread"
          >スレッドを開く</a>
        </v-col>
      </v-row>
    </v-list-item-content>
    <Actions
      :absolute="true"
      :show="showActions || showReaction"
    >
      <ReactionAction
        @hidden="showReaction = false"
        @shown="showReaction = true"
        @select:reaction="selectReaction"
      />
      <CommentAction
        v-if="commentable"
        @click.stop.prevent="moveToThread"
      />
    </Actions>
    <DialogEditGift
      v-if="isMine"
      v-model="editDialog"
      :gift="gift"
    />
    <DialogDeleteGift
      v-model="deleteDialog"
      :gift="gift"
      :is-mine="isMine"
    />
    <DialogRestoreGift
      v-model="restoreDialog"
      :gift="gift"
    />
  </v-list-item>
</template>
<script lang="ts">
import Component from 'vue-class-component'
import GiftListBase from '@/components/organisms/gifts/list/GiftListBase'
import Gift from '@/store/models/Gift'

@Component
export default class extends GiftListBase {
  mounted(): void {
    this.receive()
  }

  async receive(): Promise<void> {
    if (this.currentUser.id !== this.gift?.recipient_id) return
    if (this.gift?.accepted_at) return
    const { data } = await this.$http.patch(['takes', this.gift.id].join('/'))
    Gift.insertOrUpdate({ data })
  }
}
</script>
<style lang="scss" scoped>
@import './gift-list-item';
</style>
