/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
import Vuetify from 'vuetify'
import VeeValidate from 'vee-validate'
import i18n from '@/plugins/i18n'
import VueHttp from '@/plugins/vue-http'
import VueMarked from '@/plugins/vue-marked'
import VueMoment from '@/plugins/vue-moment'
import * as _ from 'lodash'
import VueTheMask from 'vue-the-mask'
import VueOmise from '@/plugins/vue-omise'
import VueRollbar from '@/plugins/vue-rollbar'
import * as Sentry from '@sentry/vue'
import HttpError from '@/utils/HttpError'
import notification from '@/utils/notification'
import devise from '@/utils/auth/DeviseAuthToken'
import VueMeta from 'vue-meta'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import VueFirebase from '@/plugins/vue-firebase'
import { register } from 'register-service-worker'
import Router from 'vue-router'

let Vue
export default (_Vue, { router }: { router?: Router } = {}): void => {
  if (typeof Vue !== 'undefined') {
    _Vue = Vue
  }
  _Vue.config.productionTip = process.env.NODE_ENV !== 'production'
  const environment = process.env.RACK_DEV_MARK_ENV || process.env.ENVIRONMENT_NAME || 'dev'
  _Vue.prototype._ = _
  _Vue.prototype.environment = environment
  _Vue.prototype.envMark = environment === 'production' ? '' : `(${environment})`
  register(`${process.env.BASE_URL}service-worker.js`)
  if (
    process.env.VUE_APP_FIREBASE_API_KEY &&
    process.env.VUE_APP_FIREBASE_PROJECT_ID &&
    process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID &&
    process.env.VUE_APP_FIREBASE_APP_ID
  ) {
    _Vue.use(VueFirebase, {
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
      messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.VUE_APP_FIREBASE_APP_ID,
    })
  }
  _Vue.use(VueMeta)
  _Vue.use(VueHttp)
  _Vue.use(VueMarked)
  _Vue.use(Vuetify)
  _Vue.use(VueMoment)
  _Vue.use(VueTheMask)
  _Vue.use(VueOmise, {
    publicKey: process.env.OMISE_PUBLIC_API_KEY,
  })
  _Vue.use(VeeValidate, {
    i18nRootKey: 'validations',
    i18n,
    dictionary: {
      ja: require('vee-validate/dist/locale/ja'),
    },
    events: 'blur',
  })
  if (process.env.ROLLBAR_CLIENT_ACCESS_TOKEN) {
    _Vue.use(VueRollbar, {
      accessToken: process.env.ROLLBAR_CLIENT_ACCESS_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
      enabled: true,
      environment,
      payload: {
        client: {
          javascript: {
            code_version: process.env.GIT_SHA,
            source_map_enabled: true,
            guess_uncaught_frames: true,
          },
        },
      },
    })
    _Vue.config.errorHandler = (err, _vm, _info) => {
      _Vue.rollbar.error(err)
    }
  }
  let timeout: number = null
  window.addEventListener('unhandledrejection', ({ reason }: { reason: Error | HttpError }) => {
    let status = 0
    if (typeof reason['status'] !== 'undefined') {
      status = reason['status']
    }
    if (reason.message === 'Unauthorized') {
      status = 401
    }
    if (status === 401) {
      const message: string = i18n.t('message.unauthorized') as string
      notification.close()
      notification.warning(message)
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      timeout = window.setTimeout(() => {
        devise.clear()
        location.href = '/logout'
      }, 2000)
    }
  })
  if (process.env.SENTRY_DSN) {
    Sentry.init({
      release: process.env.GIT_SHA,
      dsn: process.env.SENTRY_DSN,
      Vue: _Vue,
      attachProps: true,
      logErrors: true,
      environment,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
      ],
    })
  }
}
