/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
import { Collection, Fields, Item } from '@vuex-orm/core'
import Feeling from '@/store/models/Feeling'
import Reaction from '@/store/models/Reaction'
import Comment from '@/store/models/Comment'
import { User } from '@/store/models/Person'
import File from '@/store/models/File'
import Model from '@/store/models/Model'
import Me from '@/store/models/Me'
import _map from 'lodash/map'
import _isNil from 'lodash/isNil'

export default class Tweet extends Model {
  static entity = 'Tweet'
  static _endpoint = 'tweets'
  id!: number
  text!: string
  user_id!: number
  user!: Item<User>
  feeling_id!: number
  feeling: Item<Feeling>
  reactions: Collection<Reaction>
  comments: Collection<Comment>
  edited!: boolean
  created_at?: string
  updated_at?: string
  deleted_at?: string
  file_ids: number[]
  files: Collection<File>
  tags: string[]

  static fields(): Fields {
    return {
      id: this.attr(null),
      text: this.attr(null),
      user_id: this.attr(null),
      user: this.belongsTo(User, 'user_id'),
      feeling_id: this.attr(null),
      feeling: this.belongsTo(Feeling, 'feeling_id'),
      reactions: this.morphMany(Reaction, 'reactable_id', 'reactable_type'),
      comments: this.morphMany(Comment, 'commentable_id', 'commentable_type'),
      edited: this.attr(false),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      deleted_at: this.attr(null),
      file_ids: this.attr([]),
      files: this.hasManyBy(File, 'file_ids'),
      tags: this.attr(null),
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async create({ data: _data }): Promise<any> {
    const data = await this.doProc(_data)
    return this.dispatch('create', { data })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async insert({ data: _data }): Promise<any> {
    const data = await this.doProc(_data)
    return this.dispatch('insert', { data })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async update({ data: _data }): Promise<any> {
    const data = await this.doProc(_data)
    return this.dispatch('update', { data })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async insertOrUpdate({ data: _data }): Promise<any> {
    const data = await this.doProc(_data)
    return this.dispatch('insertOrUpdate', { data })
  }

  static async doProc(data: Item<Tweet> | Collection<Tweet>): Promise<Item<Tweet> | Collection<Tweet>> {
    if (_isNil(data)) return data
    await Me.load()
    const currentUser = Me.query().first()
    return _map(Array.isArray(data) ? data : [data], (item: Tweet) => {
      const storedItem = this.find(item.id)
      if (_isNil(storedItem)) return this.format(item)
      if (_isNil(item?.deleted_at)) return this.format(item)
      if (currentUser.id !== item.user_id && !currentUser.admin) return this.format(item)
      item.text = storedItem.text
      return this.format(item)
    })
  }

  static format(tweet: Item<Tweet>): Item<Tweet> {
    if (tweet.user.feeling) {
      tweet['feeling'] = tweet.user.feeling
      delete tweet.user.feeling
    }
    tweet.reactions = tweet.reactions.map((reaction) => Reaction.format(reaction))
    tweet.comments = tweet.comments.map((comment) => Comment.format(comment))
    return tweet
  }
}
