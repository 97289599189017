<template>
  <div class="ma-0 pa-0">
    <div
      v-if="$vuetify.breakpoint.xsOnly"
      class="d-flex align-items-center"
    >
      <v-icon
        aria-label="もらった"
        class="mr-1"
      >
        {{ icons.mdiGiftOpen }}
      </v-icon>
      {{ received }}&nbsp;/&nbsp;<v-icon
        aria-label="もらえる予定"
        class="mr-1"
      >
        {{ icons.mdiGift }}
      </v-icon>
      {{ pending }}&nbsp;/&nbsp;<v-icon
        aria-label="おくれる"
        class="mr-1"
      >
        {{ icons.mdiWallet }}
      </v-icon>
      {{ balance }}
    </div>
    <div
      v-else
      class="d-flex"
    >
      {{ received }}&nbsp;もらった&nbsp;/&nbsp;{{ pending }}&nbsp;もらえる予定&nbsp;/&nbsp;{{ balance }}&nbsp;おくれる
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { mdiGift, mdiGiftOpen, mdiWallet } from '@mdi/js'

@Component
export default class extends Vue {
  readonly icons = { mdiWallet, mdiGift, mdiGiftOpen }
  @Prop({ default: 0 })
  readonly balance!: number
  @Prop({ default: 0 })
  readonly received!: number
  @Prop({ default: null })
  readonly pending?: number
}
</script>
