<template>
  <v-slider
    :max="max"
    :min="min"
    :value="value"
    class="align-end"
    thumb-label="always"
    @input="onInput"
  >
    <template #append>
      <v-text-field
        :max="max"
        :min="min"
        :value="value"
        autocomplete="off"
        single-line
        type="number"
        @input="onInput"
      />
    </template>
  </v-slider>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import Debounce from '@/decorators/vue-debounce-decorator'

@Component
export default class extends Vue {
  @Prop({ default: 0 })
  readonly value!: number
  @Prop({ default: 0 })
  readonly max!: number
  @Prop({ default: 0 })
  readonly min!: number

  internalValue: number

  @Debounce()
  onInput(value): void {
    this.internalValue = value
    this.input(value)
  }

  @Emit('input')
  input(value): number {
    return value
  }
}
</script>
