<template>
  <v-container
    class="p-profile grey lighten-4 fill-height flex-column align"
    fluid
  >
    <div class="p-profile__header">
      <v-responsive
        aspect-ratio="3/1"
        max-height="200px"
        min-height="120px"
      >
        <v-img
          :src="_.get(user, 'coverImage')"
          max-height="200px"
          min-height="120px"
        >
          <template #placeholder>
            <v-row
              align="center"
              class="fill-height ma-0 pa-0"
              justify="center"
            >
              <v-progress-circular
                color="primary"
                indeterminate
              />
            </v-row>
          </template>
        </v-img>
      </v-responsive>
    </div>
    <div class="p-profile__content">
      <v-sheet
        class="p-profile__actions pa-0 mx-0 mt-0 mb-3 flex-wrap align-end justify-space-between flex-row d-flex"
        color="grey lighten-4"
      >
        <div class="p-profile__avatar grey lighten-4">
          <v-avatar size="100px">
            <v-img
              :src="_.get(user, 'avatarImage')"
              alt="Avatar"
            >
              <template #placeholder>
                <v-row
                  align="center"
                  class="fill-height ma-0 pa-0"
                  justify="center"
                >
                  <v-progress-circular
                    color="primary"
                    indeterminate
                  />
                </v-row>
              </template>
            </v-img>
          </v-avatar>
          <span class="c-feelingBadge">{{ _.get(user, 'feeling.emoji', defaultFeeling) }}</span>
        </div>
        <div class="flex-wrap align-end justify-start flex-row d-flex">
          <div class="mr-2 mb-3">
            <EditProfileDialog v-if="isMine">
              <template #activator="{ on: dialog }">
                <v-tooltip bottom>
                  <template #activator="{ on: tooltip }">
                    <v-btn
                      elevation="0"
                      icon
                      outlined
                      @click="
                        dialog.click($event)
                        tooltip.mouseleave()
                      "
                      @mouseenter="tooltip.mouseenter"
                      @mouseleave="tooltip.mouseleave"
                    >
                      <v-icon dark>
                        {{ icons.mdiPencil }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>プロフィールを編集する</span>
                </v-tooltip>
              </template>
            </EditProfileDialog>
            <v-tooltip
              v-else
              bottom
            >
              <template #activator="{ on: tooltip }">
                <v-btn
                  color="primary"
                  elevation="0"
                  icon
                  outlined
                  @click="thanksDialog = true; tooltip.mouseleave()"
                  @mouseenter="tooltip.mouseenter"
                  @mouseleave="tooltip.mouseleave"
                >
                  <v-icon>{{ icons.mdiHandHeart }}</v-icon>
                </v-btn>
              </template>
              <span>感謝をおくる</span>
            </v-tooltip>
            <DialogEditGift
              v-model="thanksDialog"
              :recipient-id="userId"
            />
          </div>
          <div
            v-if="!isMine"
            class="mb-3"
          >
            <v-btn
              v-show="!isFollowed"
              color="secondary"
              depresse
              elevation="0"
              min-width="110px"
              rounded
              @click.prevent.stop="follow"
            >
              フォローする
            </v-btn>
            <v-dialog
              v-model="unfollowDialog"
              max-width="600px"
            >
              <template #activator="{ on }">
                <v-btn
                  v-show="isFollowed"
                  elevation="0"
                  min-width="110px"
                  outlined
                  rounded
                  v-on="on"
                >
                  フォロー中
                </v-btn>
              </template>
              <v-card>
                <v-card-title>{{ _.get(user, 'name') }}さんのフォローを解除しますか？</v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    text
                    @click.prevent.stop="unfollowDialog = false"
                  >
                    {{ $t('button.cancel') }}
                  </v-btn>
                  <v-btn
                    color="error"
                    text
                    @click.prevent.stop="unfollow"
                  >
                    {{ $t('button.unfollow') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </v-sheet>
      <v-sheet
        class="p-profile__user"
        color="grey lighten-4 mx-0 mt-1 mb-3"
      >
        <v-col
          v-if="!_.isNil(user?.department)"
          class="p-profile__departmentName text-caption"
        >
          <strong v-text="user?.department?.name" />
        </v-col>
        <v-col class="p-profile__userName d-flex flex-row align-center">
          <span
            v-if="!_.isNil(user?.employee_id)"
            class="text-caption mr-1"
          >{{ user?.employee_id }}</span><strong v-text="_.get(user, 'name')" />
        </v-col>
        <v-col
          class="p-profile__userEmail"
        >
          <span v-html="_.get(user, 'email')" />
        </v-col>
        <v-col
          v-if="user?.tags?.length > 0"
          class="p-profile__userTags"
        >
          <TagGroup
            :value="user?.tags"
            small
          />
        </v-col>
        <v-col class="p-profile__userPoint">
          <Points
            v-if="tenant?.exchange_mode === 'drawable'"
            :balance="user?.point || 0"
            :pending="(user?.keeps || 0) - (user?.exchangeable || 0)"
            :received="user?.exchangeable || 0"
          />
          <Points
            v-else
            :balance="user?.point || 0"
            :received="user?.takes || 0"
          />
        </v-col>
      </v-sheet>
      <v-sheet
        v-if="_.get(user, 'description')"
        class="p-profile__description"
        color="grey lighten-4 ma-0"
      >
        <div
          class="p-profile__descriptionText"
          v-text="_.get(user, 'description')"
        />
      </v-sheet>
    </div>
    <v-tabs
      background-color="grey lighten-4"
      class="p-profile__tabs white"
      show-arrows
    >
      <v-tab :to="{ name: 'member/tweets' }">
        つぶやき
      </v-tab>
      <v-tab :to="{ name: 'member/takes' }">
        もらった
      </v-tab>
      <v-tab :to="{ name: 'member/gives' }">
        おくった
      </v-tab>
      <v-tab
        v-if="isMine"
        :to="{ name: 'member/followings' }"
      >
        フォロー中のメンバー
      </v-tab>
      <v-tabs-items>
        <router-view />
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>
<script lang="ts">
import Vue from 'vue'
import _get from 'lodash/get'
import { defaultFeeling } from '@/store/models/Feeling'
import Component from 'vue-class-component'
import Me from '@/store/models/Me'
import { User } from '@/store/models/Person'
import { Watch } from 'vue-property-decorator'
import { InstanceOf, Item } from '@vuex-orm/core'
import _isNil from 'lodash/isNil'
import { mdiGift, mdiHandHeart, mdiPencil, mdiWallet } from '@mdi/js'
import TagGroup from '@/components/organisms/TagGroup.vue'
import EditProfileDialog from '@/components/organisms/profile/EditProfileDialog.vue'
import Tenant from '@/store/models/Tenant'
import DialogEditGift from '@/components/organisms/gifts/dialogs/DialogEditGift.vue'
import Points from '@/components/organisms/gifts/Points.vue'

type DisplayedPoint = 'takes' | 'keeps'

@Component({
  components: {
    TagGroup,
    EditProfileDialog,
    DialogEditGift,
    Points,
  },
})
export default class extends Vue {
  readonly defaultFeeling = defaultFeeling
  readonly icons = { mdiPencil, mdiWallet, mdiGift, mdiHandHeart }
  unfollowDialog = false
  thanksDialog = false

  get tenant(): Item<InstanceOf<Tenant>> {
    return this.currentUser?.tenant
  }

  get currentUser(): Item<InstanceOf<Me>> {
    return Me.query().with('tenant').first()
  }

  get user(): Item<InstanceOf<User>> {
    const user: Item<InstanceOf<User>> = User.query().with(['department', 'feeling']).find(this.userId) || {}
    if (!_isNil(user.deleted_at)) return null
    if (_isNil(user.status !== 'active')) return null
    return user
  }

  get userId(): number {
    return +this.$route.params.id
  }

  get isMine(): boolean {
    return this.userId === _get(this.currentUser, 'id')
  }

  get isFollowed(): boolean {
    return this.currentUser.following(this.userId)
  }

  get displayedPointProp(): DisplayedPoint {
    if (this.tenant?.exchange_mode === 'drawable') {
      return 'keeps'
    }
    return 'takes'
  }

  mounted(): void {
    this.fetchData()
  }

  @Watch('userId')
  fetchData(): void {
    User.fetch(this.userId)
  }

  follow(): void {
    this.$http.post('my/relationship', { 'user_id': this.userId }).then(({ data }) => {
      Me.update({ data })
    })
  }

  unfollow(): void {
    this.$http.delete('my/relationship', { data: { 'user_id': this.userId } }).then(({ data }) => {
      this.unfollowDialog = false
      Me.update({ data })
    })
  }
}
</script>
<style lang="scss" scoped>
.p-profile {
  position: relative;
  z-index: 0;
  flex: 5;
  padding: 0;

  & &__header {
    position: relative;
    width: 100%;
  }

  & &__avatar {
    position: relative;
    z-index: 3;
    margin-top: -20%;
    border-radius: 50%;
  }

  & &__actions {
    width: 100%;
  }

  & &__user {
    width: 100%;
    padding: 0;

    > * {
      width: 100%;
      margin: 0;
    }

    > :not(:first-of-type) {
      margin-top: 4px;
    }
  }

  & &__description {
    width: 100%;
    padding: 0;

    &Text {
      white-space: pre-wrap;
    }
  }

  & &__content {
    width: 100%;
    padding: 16px;
  }
}

.c-feelingBadge {
  font-size: 25px;
}
</style>
