import { defaultFeeling } from '@/store/models/Feeling'
import Avatar from '@/components/organisms/Avatar.vue'
import Component, { mixins } from 'vue-class-component'
import Me from '@/store/models/Me'
import { Emit, Prop } from 'vue-property-decorator'
import { Item, Record } from '@vuex-orm/core'
import Gift from '@/store/models/Gift'
import Actable from '@/mixins/actable'
import Reactable from '@/mixins/reactable'
import TagGroup from '@/components/organisms/TagGroup.vue'
import _get from 'lodash/get'
import _isNil from 'lodash/isNil'
import MessageEditor from '@/components/organisms/editor/MessageEditor.vue'
import CommentAction from '@/components/organisms/actions/CommentAction.vue'
import IconButton from '@/components/atoms/IconButton.vue'
import { User } from '@/store/models/Person'
import { mdiCheckboxMarkedCircle, mdiCircleOutline, mdiDelete, mdiDeleteRestore, mdiPencil } from '@mdi/js'
import DialogDeleteGift from '@/components/organisms/gifts/dialogs/DialogDeleteGift.vue'
import DialogEditGift from '@/components/organisms/gifts/dialogs/DialogEditGift.vue'
import DialogRestoreGift from '@/components/organisms/gifts/dialogs/DialogRestoreGift.vue'

@Component({
  components: {
    Avatar,
    TagGroup,
    MessageEditor,
    CommentAction,
    IconButton,
    DialogDeleteGift,
    DialogRestoreGift,
    DialogEditGift,
  },
})
export default class GiftListBase extends mixins(Actable, Reactable) {
  readonly icons: { [key: string]: string } = {
    mdiCheckboxMarkedCircle,
    mdiCircleOutline,
    mdiPencil,
    mdiDelete,
    mdiDeleteRestore,
  }
  readonly defaultFeeling = defaultFeeling
  readonly reactableModel = Gift
  @Prop({ default: {} })
  readonly value!: Record
  @Prop({ default: false })
  readonly disabled!: boolean
  @Prop({ default: false })
  readonly commentable?: boolean
  deleteDialog = false
  restoreDialog = false
  editDialog = false

  get isDeleted(): boolean {
    return !_isNil(this.gift?.deleted_at)
  }

  get isAdminMode(): boolean {
    return this.$store.state.screen.mode === 'admin'
  }

  get message(): string {
    return this.$marked(this.gift?.message)
  }

  get gift(): Item<Gift> {
    return this.item
  }

  get item(): Item<Gift> {
    if (_isNil(this.value)) return null
    return Gift.query()
      .with([
        'recipient',
        'recipient_feeling',
        'sender',
        'sender_feeling',
        'categories',
        'reactions',
        'reactions.user',
        'comments',
      ])
      .find(this.value.id)
  }

  get recipient(): Item<User> {
    return this.gift?.recipient
  }

  get recipientName(): string {
    return this.gift?.recipientName
  }

  get sender(): Item<User> {
    return this.gift?.sender
  }

  get senderName(): string {
    return this.gift?.senderName
  }

  get currentUser(): Item<Me> {
    return Me.query().first()
  }

  get isMine(): boolean {
    return this.gift?.sender_id === _get(this.currentUser, 'id')
  }

  get isEdited(): boolean {
    return _get(this.gift, 'edited')
  }

  @Emit('startEditing')
  startEditing(): void {
    return undefined
  }

  @Emit('finishEditing')
  finishEditing(): void {
    return undefined
  }

  showDeleteDialog(): void {
    this.deleteDialog = true
  }

  showRestoreDialog(): void {
    this.restoreDialog = true
  }

  showEditDialog(): void {
    this.editDialog = true
  }

  moveToThread(): void {
    this.$router.push({
      name: 'takes',
      params: { id: `${this.gift?.id}` },
    })
  }
}
