<template>
  <v-form
    class="layout p-post-tweet__text"
    @submit.stop.prevent="post"
  >
    <v-row class="ma-0 pa-0">
      <v-col class="col pa-0">
        <MessageEditor
          v-model="tweet.text"
          v-validate="'required|max:280'"
          :attachments="tweet.attachments"
          :image="true"
          :placeholder="message"
          :tag-model="TweetTag"
          class="p-post-tweet__text"
          data-vv-name="tweetText"
          data-vv-validate-on="blur|input"
          max-height="200px"
          @beginUpload="imageUploading.push(true)"
          @changeAttachments="tweet.attachments = $event"
          @endUpload="imageUploading.pop()"
          @keydown.alt.enter.stop.prevent.capture="post"
          @keydown.ctrl.enter.stop.prevent.capture="post"
          @keydown.shift.enter.stop.prevent.capture="post"
          @keydown.meta.enter.stop.prevent.capture="post"
        />
      </v-col>
      <v-btn
        :disabled="isInvalid"
        :loading="loading"
        class="ml-2 mb-5 align-self-end"
        color="primary"
        type="submit"
      >
        つぶやく
      </v-btn>
    </v-row>
  </v-form>
</template>
<script lang="ts">
import Vue from 'vue'
import notification from '@/utils/notification'
import Tweet from '@/store/models/Tweet'
import Me from '@/store/models/Me'
import Component from 'vue-class-component'
import { Record } from '@vuex-orm/core'
import MessageEditor from '@/components/organisms/editor/MessageEditor.vue'
import { MessageEditorValue } from '@/components/organisms/editor/MessageEditorValue'
import { TweetTag } from '@/store/models/Tag'
import notifyResponseError from '@/utils/notifyResponseError'

const messages = ['ひとこと'] as const
@Component({
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    MessageEditor,
  },
})
export default class PostTweetComponent extends Vue {
  loading = false
  imageUploading: boolean[] = []
  messageIndex = this.randomiseMessageIndex()
  readonly TweetTag = TweetTag
  private tweet: MessageEditorValue = {
    text: '',
    attachments: [],
  }

  get message(): string {
    return messages[this.messageIndex]
  }

  get currentUser(): Record {
    return Me.query().first()
  }

  get isInvalid(): boolean {
    if (this.loading) return true
    if (this.imageUploading.filter((value) => value).length > 0) return true
    if (this.tweet.text.replace(/[\s\t\n]+/g, '') === '') return true
    return this.$validator.errors && this.$validator.errors.count() > 0
  }

  async post(): Promise<void> {
    if (this.isInvalid) return
    if (!(await this.$validator.validateAll())) return
    this.loading = true
    const tweet: {
      text: string
      'user_id': string
      attachment_ids?: number[]
    } = {
      text: this.tweet.text,
      'user_id': this.currentUser.id,
    }
    if (this.tweet.attachments.length > 0) {
      tweet['attachment_ids'] = this.tweet.attachments.map((file) => file.id)
    }
    this.tweet = {
      text: '',
      attachments: [],
    }
    this.reset()
    try {
      const { data } = await this.$http.post('tweets', tweet)
      Tweet.insertOrUpdate({ data })
      TweetTag.fetchAll({ useCache: false })
      notification.success(this.$t('message.posted') as string)
    } catch (err) {
      if (err.status === 422) {
        notifyResponseError(err.response)
      }
      throw err
    } finally {
      this.reset()
      this.loading = false
    }
  }

  reset(): void {
    this.messageIndex = this.randomiseMessageIndex()
    this.$validator.pause()
    this.$validator.reset().then(() => this.$validator.resume())
    this.tweet = {
      text: '',
      attachments: [],
    }
  }

  randomiseMessageIndex(): number {
    return Math.ceil(Math.random() * messages.length) - 1
  }
}
</script>
