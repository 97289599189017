<template>
  <div>
    <div
      ref="dummyEditorMain"
      :class="{ 'dummy-editor-main--fullscreen': $vuetify.breakpoint.xsOnly }"
      class="dummy-editor-main"
    />
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :value="value"
      content-class="overflow-visible"
      max-width="600"
      @input="onInputDialog"
    >
      <template #activator="{ on }">
        <slot
          :on="on"
          name="activator"
        />
      </template>
      <template #default="dialog">
        <v-form
          v-model="isValid"
          class="fill-height"
          @submit.stop.prevent="onSubmit(dialog)"
        >
          <v-card
            :class="{ 'rounded-0': $vuetify.breakpoint.xsOnly }"
            class="d-flex flex-column fill-height overflow-visible"
            flat
          >
            <v-card-title
              class="text-h5 grey lighten-2"
              primary-title
            >
              {{ isNew ? '感謝をおくる' : 'おくった感謝を編集する' }}
            </v-card-title>
            <v-card-text
              class="pa-4 flex-grow-1 overflow-y-auto"
            >
              <v-row class="ma-0 pa-0">
                <v-col v-if="isNew">
                  <v-combobox
                    v-model="recipient"
                    v-validate="'required'"
                    :error-messages="$validator.errors.collect('recipient')"
                    :items="recipients"
                    :loading="isLoadingUsers"
                    class="mt-0 mb-2"
                    data-vv-name="recipient"
                    item-value="id"
                    label="宛先"
                  />
                </v-col>
                <v-col
                  v-else
                  class="text-subtitle-1 text--primary px-3"
                >
                  宛先： {{ gift.recipient.name }} さん
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0">
                <v-col class="ma-0 pa-0">
                  <MessageEditor
                    v-validate="'required|max:500'"
                    :attachments="editData.attachments"
                    :class="{ 'gift-message--fullscreen': $vuetify.breakpoint.xsOnly }"
                    :error-messages="$validator.errors.collect('message')"
                    :max-height="editorMaxHeight"
                    :value="editData.text"
                    class="flex-grow-1 overflow-y-auto gift-message"
                    data-vv-name="message"
                    data-vv-validate-on="blur|input"
                    @changeAttachments="editData.attachments = $event"
                    @input="editData.text = $event"
                  />
                </v-col>
              </v-row>
              <v-row
                v-if="categories.length >0"
                class="ma-0 pa-0"
              >
                <v-col class="ma-0 py-0">
                  <v-combobox
                    :items="categories"
                    :loading="isLoadingCategories"
                    :value="editData.categories"
                    autocomplete="off"
                    chips
                    clearable
                    deletable-chips
                    item-text="name"
                    label="タグ"
                    multiple
                    @change="onChangeCategories"
                  />
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0">
                <v-col>
                  <InputPoint
                    v-model="editData.point"
                    :max="maxPoint"
                    :min="minPoint"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn
                :disabled="isLoadingCategories || isLoadingMyInfo || isLoadingGift"
                text
                @click.stop="onClickClose(dialog)"
              >
                キャンセル
              </v-btn>
              <v-btn
                :disabled="isLoadingCategories || isLoadingMyInfo || isLoadingGift || !isValid || isEmptyMessage"
                :loading="isLoadingCategories || isLoadingMyInfo || isLoadingGift"
                color="primary"
                text
                type="submit"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </template>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import MessageEditor from '@/components/organisms/editor/MessageEditor.vue'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { Collection, Item, Record } from '@vuex-orm/core'
import Gift from '@/store/models/Gift'
import _isNil from 'lodash/isNil'
import Category from '@/store/models/Category'
import Me from '@/store/models/Me'
import notifyResponseError from '@/utils/notifyResponseError'
import { MessageEditorValue } from '@/components/organisms/editor/MessageEditorValue'
import { User } from '@/store/models/Person'
import InputPoint from '@/components/atoms/InputPoint.vue'
import notification from '@/utils/notification'
import { AxiosPromise } from 'axios'

type Recipient = User & { text: string }

@Component({
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    MessageEditor,
    InputPoint,
  },
})
export default class extends Vue {
  $refs: {
    dummyEditorMain: HTMLDivElement
  }

  @Prop({ default: null })
  readonly value?: boolean
  @Prop({ default: null })
  readonly gift!: Partial<Item<Gift>>
  @Prop({ default: null })
  readonly recipientId?: number

  @Prop({ default: '' })
  readonly message!: string
  @Prop({ default: false })
  readonly disabledDelete!: boolean

  editData: MessageEditorValue & {
    point?: number;
    categories?: Collection<Category>
  } = {
    text: '',
    point: 0,
    categories: [],
    attachments: [],
  }
  isValid = false
  isLoadingCategories = false
  isLoadingMyInfo = false
  isLoadingGift = false
  isLoadingUsers = false
  editorMaxHeight = ''

  recipient: Recipient = null

  get isNew(): boolean {
    return _isNil(this.gift?.id)
  }

  get item(): Item<Gift> | null {
    if (this.isNew) return null
    return Gift.query().with([
      'categories',
      'reactions',
      'comments',
    ]).whereId(this.gift.id).first()
  }

  get maxPoint(): number {
    if (this.isNew) {
      return this.my?.point
    }
    return this.gift?.adjustable_point_range[1]
  }

  get minPoint(): number {
    if (this.isNew) {
      return 0
    }
    return this.gift?.adjustable_point_range[0]
  }

  get categories(): Collection<Category> {
    return Category.query().orderBy('display_order', 'asc').orderBy('id', 'asc').all()
  }

  get isEmptyMessage(): boolean {
    return (this.editData.text.replace(/[\s\t\n]+/g, '') === '')
  }

  get my(): Item<Me> | null {
    return Me.query().first()
  }

  get recipients(): Recipient[] {
    const users: Collection<User> = User.available()
      .where(({ id }) => {
        return id !== this.my?.id
      })
      .get()
    return users.map(user => {
      return {
        ...user,
        text: `${user.name} (${user.email})`,
      } as Recipient
    })
  }

  @Emit()
  input(value: boolean): boolean {
    return value
  }

  @Emit('click:hash-tag')
  clickHashTag(value: string): string {
    return value
  }

  @Emit('click:delete')
  clickDelete(): void {
    return undefined
  }

  @Watch('value')
  watchValue(value: boolean): void {
    if (value) {
      this.$validator.reset()
      this.fetchMyInfo()
      this.fetchCategories()
      this.fetchUsers()
      if (this.isNew) {
        this.resetRecipient()
        this.resetEditData()
      } else {
        this.fetchGift()
        this.setEditData()
      }
      this.editorMaxHeight = getComputedStyle(this.$refs.dummyEditorMain).height
    }
  }

  onInputDialog(value: boolean): void {
    this.input(value)
  }

  onClickClose(dialog: { value: boolean }): void {
    dialog.value = false
  }

  onSubmit(dialog: { value: boolean }): void {
    this.save()
    dialog.value = false
  }

  onChangeCategories(categories: Collection<Category>): void {
    this.editData.categories = (categories || []).filter(category => typeof category !== 'string')
  }

  private async save(): Promise<void> {
    if (!(await this.$validator.validateAll())) return
    try {
      const { data } = await (() => this.isNew ? this.post() : this.patch())()
      Gift.insertOrUpdate({ data })
      this.fetchMyInfo()
      notification.success((this.isNew ? this.$t('message.gaveThanks') : this.$t('message.updated')) as string)
    } catch (err) {
      if (err.status === 422) {
        notifyResponseError(err.response.data)
      } else {
        throw err
      }
    }
  }

  private patch(): AxiosPromise {
    return this.$http.patch(['gifts', this.gift?.id].join('/'), {
      message: this.editData.text,
      point: this.editData.point,
      categories: this.editData.categories,
    })
  }

  private post(): AxiosPromise {
    const gift: {
      message: string
      point: number
      categories: Record[]
      'user_id': number
      attachment_ids?: number[]
    } = {
      message: this.editData.text,
      point: this.editData.point,
      categories: this.editData.categories,
      'user_id': this.recipient.id,
    }
    if (this.editData.attachments.length > 0) {
      gift['attachment_ids'] = this.editData.attachments.map(({ id }) => id)
    }
    return this.$http.post(['users', this.recipient.id, 'takes'].join('/'), gift)
  }

  private async fetchUsers(): Promise<void> {
    this.isLoadingUsers = true
    await User.fetchAll()
    this.isLoadingUsers = false
  }

  private async fetchCategories(): Promise<void> {
    this.isLoadingCategories = true
    await Category.fetchAll()
    this.isLoadingCategories = false
  }

  private async fetchMyInfo(): Promise<void> {
    this.isLoadingMyInfo = true
    await Me.fetchAll()
    this.isLoadingMyInfo = false
  }

  private async fetchGift(): Promise<void> {
    this.isLoadingGift = true
    await Gift.fetch(this.gift.id)
    this.setEditData()
    this.isLoadingGift = false
  }

  private setEditData(): void {
    this.editData = {
      text: this.item.message,
      point: this.item.point,
      categories: this.item.categories,
      attachments: [], // TODO: Fileからアタッチメント取得
    }
  }

  private resetEditData(): void {
    this.editData = {
      text: '',
      point: 0,
      categories: [],
      attachments: [],
    }
  }

  private resetRecipient(): void {
    this.recipient = null
    if (_isNil(this.recipientId)) return
    const user: Item<User> = User.find(this.recipientId)
    if (_isNil(user)) return
    this.recipient = {
      ...user,
      text: `${user.name} (${user.email})`,
    } as Recipient
  }
}
</script>
<style lang="scss" scoped>

$dialog-header-height: 64px;
$dialog-footer-height: 52px + 1px;
$dialog-header-footer-height: $dialog-header-height + $dialog-footer-height;
$dialog-body-height: calc(90vh - #{$dialog-header-footer-height});
$dialog-body-fullscreen-height: calc(100vh - #{$dialog-header-footer-height});
$dialog-body-padding: 16px;
$dialog-body-padding-y: $dialog-body-padding + $dialog-body-padding;
$recipient-height: 52px;
$category-height: 80px;
$point-height: 102px;
$other-than-message: $dialog-header-footer-height + $dialog-body-padding-y + $recipient-height + $category-height + $point-height;
$gift-message-height: calc(90vh - #{$other-than-message});
$gift-message-height-fullscreen: calc(100vh - #{$other-than-message});
$editor-header-height: 42px + 1px;
$editor-footer-height: 14px + 8px;
$editor-header-footer-height: $editor-header-height + $editor-footer-height;
$other-than-editor: $other-than-message + $editor-header-footer-height + 2px;
$editor-main-height: calc(90vh - #{$other-than-editor});
$editor-main-height-fullscreen: calc(100vh - #{$other-than-editor});

.gift-message {
  min-height: 200px;
  max-height: $gift-message-height;

  &--fullscreen {
    height: $gift-message-height-fullscreen;
    max-height: $gift-message-height-fullscreen;
  }
}

.dummy-editor-main {
  position: fixed;
  height: $editor-main-height;

  &--fullscreen {
    height: $editor-main-height-fullscreen;
  }
}
</style>
