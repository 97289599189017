<template>
  <v-btn
    :color="color"
    icon
    small
    tile
    @click="click"
  >
    <v-icon>{{ icon }}</v-icon>
  </v-btn>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'

@Component
export default class extends Vue {
  @Prop({ default: undefined })
  readonly icon!: string
  @Prop({ default: null })
  readonly color?: string

  @Emit()
  click(event: MouseEvent): MouseEvent {
    return event
  }
}
</script>
