<template>
  <DeleteDialog
    :disabled-delete="!willDelete"
    :message="message"
    :title="isMine ? `おくった感謝を削除する` : `${gift?.sender?.name} さんがおくった感謝を非表示にする`"
    :value="value"
    @input="onInputDeleteDialog"
    @click:delete="onClickDelete"
  >
    <template #top>
      <v-card-text class="pb-0">
        <v-row class="ma-0 pa-0">
          <v-col class="text-subtitle-1 text--primary px-3">
            宛先： {{ gift.recipient.name }} さん
          </v-col>
        </v-row>
      </v-card-text>
    </template>
    <template #bottom>
      <v-card-text>
        <ul>
          <li>おくったチップ: {{ gift?.point }}</li>
          <li>コメント数: {{ gift?.comments?.length }}</li>
          <li>リアクション数: {{ gift?.reactions?.length }}</li>
        </ul>
        <v-checkbox
          v-if="gift?.cancelable"
          v-model="willDelete"
          hide-details
          :label="isMine ? `この感謝を削除します。` : `この感謝を非表示にします。`"
        />
        <v-alert
          v-if="!gift?.cancelable"
          class="mt-4 mb-0"
          color="info"
          outlined
        >
          削除可能な期限が過ぎています。
        </v-alert>
      </v-card-text>
    </template>
  </DeleteDialog>
</template>
<script lang="ts">
import Vue from 'vue'
import Gift from '@/store/models/Gift'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { Item } from '@vuex-orm/core'
import notification from '@/utils/notification'
import DeleteDialog from '@/components/atoms/DeleteDialog.vue'
import Me from '@/store/models/Me'
import _cloneDeep from 'lodash/cloneDeep'

@Component({
  components: { DeleteDialog },
})
export default class extends Vue {
  @Prop({ default: null })
  readonly value?: boolean
  @Prop({ default: null })
  readonly gift?: Item<Gift>
  @Prop({ default: true })
  readonly isMine?: boolean

  willDelete = false

  get message(): string {
    return this.$marked(this.gift?.message)
  }

  @Emit()
  input(value: boolean): boolean {
    return value
  }

  async onClickDelete(): Promise<void> {
    await this.$http.delete(['gifts', this.gift?.id].join('/'))
    /* eslint-disable camelcase, @typescript-eslint/naming-convention */
    const data = _cloneDeep(this.gift)
    data.deleted_at = this.$moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    await Gift.update({ data })
    await Me.fetch({ useCache: false })
    /* eslint-enable camelcase, @typescript-eslint/naming-convention */
    notification.success(this.$t('message.deleted') as string)
  }

  onInputDeleteDialog(value: boolean): void {
    if (!value) {
      this.willDelete = false
    }
    this.input(value)
  }
}
</script>
