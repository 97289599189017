<template>
  <v-list-item>
    <v-list-item-avatar class="align-self-start c-list-item__avatar">
      <Avatar :user="gift.sender" />
      <span class="c-feelingBadge">{{ _.get(gift, 'sender_feeling.emoji', defaultFeeling) }}</span>
    </v-list-item-avatar>
    <v-list-item-content class="flex-column align-start">
      <v-row
        align="center"
        class="ma-0 pa-0 flex-wrap mb-1"
      >
        <router-link
          :to="{ name: 'member/home', params: { id: gift.sender_id } }"
          class="c-sender__name"
        >
          <strong>{{ _.get(gift, 'sender.name') }}</strong>
        </router-link>
        <div class="timeline-date ml-2">
          {{ $d($moment(gift.created_at).toDate(), 'long') }}
        </div>
        <v-spacer />
      </v-row>
      <v-row class="ma-0 pa-0 w-100">
        <div
          v-if="isDeleted"
          class="c-gift__message c-gift__message--deleted flex-grow-1"
          v-html="(isMine || isAdminMode) ? message : $t('message.inappropriate')"
        />
        <div
          v-else
          :class="{ 'c-gift__message--edited': isEdited }"
          class="c-gift__message flex-grow-1"
          v-html="message"
        />
        <v-row
          align-content="start"
          class="ma-0 pa-0 flex-grow-0 flex-shrink-0 ml-auto"
        >
          <v-spacer />
          <div v-if="isMine">
            <IconButton
              :disabled="disabled"
              :icon="icons.mdiPencil"
              @click="showEditDialog"
            />
            <IconButton
              :disabled="disabled"
              :icon="icons.mdiDelete"
              @click="showDeleteDialog"
            />
          </div>
          <div v-if="!isMine && isAdminMode">
            <IconButton
              v-if="!isDeleted"
              :disabled="disabled"
              :icon="icons.mdiDelete"
              color="error"
              @click="showDeleteDialog"
            />
            <IconButton
              v-else
              :disabled="disabled"
              :icon="icons.mdiDeleteRestore"
              color="error"
              @click="showRestoreDialog"
            />
          </div>
        </v-row>
      </v-row>
      <v-row
        align="center"
        class="ma-0 pa-0 flex-wrap mb-1"
      >
        <ReactionChipGroup
          :loading="reactionLoading"
          :value="reactions"
          @click:reaction="selectReaction"
        />
      </v-row>
    </v-list-item-content>
    <Actions
      :absolute="true"
      :show="showActions || showReaction"
    >
      <ReactionAction
        @hidden="showReaction = false"
        @shown="showReaction = true"
        @select:reaction="selectReaction"
      />
    </Actions>
    <DialogEditGift
      v-if="isMine"
      v-model="editDialog"
      :gift="gift"
    />
    <DialogDeleteGift
      v-model="deleteDialog"
      :gift="gift"
      :is-mine="isMine"
    />
    <DialogRestoreGift
      v-model="restoreDialog"
      :gift="gift"
    />
  </v-list-item>
</template>
<script lang="ts">
import Component from 'vue-class-component'
import GiftListBase from '@/components/organisms/gifts/list/GiftListBase'

@Component
export default class extends GiftListBase {
}
</script>
<style lang="scss" scoped>
@import "@/styles/markdown";

.c- {
  &list-item {
    &__avatar {
      overflow: visible;
    }
  }

  &gift {
    &__message {
      min-height: 28px;
      word-break: break-all;
      word-wrap: break-word;

      @include after-mark;

      @include after-mark('deleted');

      &--deleted {
        color: #ccc;
      }
    }
  }

  &sender {
    &__name {
      font-size: .9rem;
    }
  }
}
</style>
