const validations = {
  ja: {
    attributes: {
      password: 'パスワード',
      reEnterPassword: 'パスワード再入力',
      tweetText: 'つぶやき',
      message: 'メッセージ',
      recipient: '宛先',
      userName: 'お名前',
      userDescription: '自己紹介',
      commentBody: 'コメント',
      deletedAt: '削除日時',
      user: {
        name: '名前',
        email: 'Eメール',
        admin: '管理者',
        point: 'おくれるチップ',
        takes: 'もらったチップ',
        keeps: 'つかえるチップ',
        exchangeable: 'つかえるチップ',
        pending: 'もらえるチップ',
        tags: 'タグ',
        role: '役割',
        department: '部署',
        employeeId: '社員番号',
      },
      tenant: {
        name: 'サブドメイン',
        displayName: '会社名',
        pointsPerUnit: '一回分の付与されるチップ',
        distributionDays: '配布する曜日',
        pointExpiresAfter: '有効期限',
        exchangeMode: '運用タイプ',
      },
      category: {
        name: '感謝タグ',
      },
      creditCard: {
        number: 'クレジットカード番号',
        name: '姓名',
        exp: '有効期限',
        csc: 'セキュリティコード',
        number1: 'クレジットカード番号（1）',
        number2: 'クレジットカード番号（2）',
        number3: 'クレジットカード番号（3）',
        number4: 'クレジットカード番号（4）',
        expYear: '有効期限（年）',
        expMonth: '有効期限（月）',
        familyName: '姓',
        givenName: '名',
      },
      community: {
        name: 'コミュニティー名',
      },
      department: {
        name: '部署名',
        users: 'メンバー',
      },
      exchange: {
        point: 'チップ',
        memo: 'メモ',
      },
      confirmed: '確認',
    },
  },
}
export default validations
