<template>
  <v-form
    class="p-post-comment d-flex flex-column flex-grow-1"
    @submit.stop.prevent="post"
  >
    <div class="d-flex flex-column flex-grow-1">
      <MessageEditor
        v-model="comment.text"
        v-validate="'required|max:280'"
        :attachments="comment.attachments"
        :error-messages="commentErrorMessages"
        class="p-post-comment__text"
        data-vv-name="commentBody"
        data-vv-validate-on="blur|input"
        max-height="200px"
        placeholder="返信する"
        @changeAttachments="comment.attachments = $event"
        @keydown.alt.enter.stop.prevent.capture="post"
        @keydown.ctrl.enter.stop.prevent.capture="post"
        @keydown.shift.enter.stop.prevent.capture="post"
        @keydown.meta.enter.stop.prevent.capture="post"
      />
    </div>
    <div class="d-flex">
      <v-spacer />
      <v-btn
        :disabled="isInvalid"
        :loading="loading"
        color="primary"
        type="submit"
      >
        送信
      </v-btn>
    </div>
  </v-form>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Record } from '@vuex-orm/core'
import { Prop } from 'vue-property-decorator'
import { CommentableModel as _CommentableModel } from '@/store/models'
import { FieldError } from 'vee-validate/types/vee-validate'
import MessageEditor from '@/components/organisms/editor/MessageEditor.vue'
import { MessageEditorValue } from '@/components/organisms/editor/MessageEditorValue'
import notifyResponseError from '@/utils/notifyResponseError'

type CommentableModel = _CommentableModel
@Component({
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    MessageEditor,
  },
})
export default class PostCommentComponent extends Vue {
  @Prop({ default: null })
  readonly model!: CommentableModel
  @Prop({ default: null })
  readonly commentable!: Record
  comment: MessageEditorValue = {
    text: '',
    attachments: [],
  }
  loading = false

  get isInvalid(): boolean {
    if (this.loading) return true
    if (this.comment.text.replace(/[\s\t\n]+/g, '') === '') return true
    return this.$validator.errors && this.$validator.errors.count() > 0
  }

  get commentErrorMessages(): FieldError[] {
    if (this.isInvalid) return null
    return this.$validator.errors.collect('commentBody')
  }

  async post(): Promise<void> {
    if (this.isInvalid) return
    if (!(await this.$validator.validateAll())) return
    this.loading = true
    const comment: {
      body: string
      attachment_ids?: number[]
    } = {
      body: this.comment.text,
    }
    if (this.comment.attachments.length > 0) {
      comment['attachment_ids'] = this.comment.attachments.map((file) => file.id)
    }
    this.reset()
    this.$http
      .post([this.model.endpoint(), this.commentable.id, 'comments'].join('/'), comment)
      .then(({ data }) => {
        this.model.insertOrUpdate({ data })
        this.reset()
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => {
        if (err.status === 422) {
          notifyResponseError(err.response.data)
          return
        }
        return Promise.reject(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  reset(): void {
    this.$validator.pause()
    this.$validator.reset().then(() => this.$validator.resume())
    this.comment = {
      text: '',
      attachments: [],
    }
  }
}
</script>
<style lang="scss" scoped>
.p-post-comment {
  width: 100%;
}
</style>
