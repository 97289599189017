<template>
  <v-dialog
    v-model="dialog"
    content-class="rounded-0"
    fullscreen
    persistent
    scrollable
  >
    <v-card rounded="0">
      <v-card-title
        class="text-h5 grey lighten-2"
        primary-title
      >
        {{ user?.name }}のチップ利用履歴
        <v-spacer />
        <v-btn @click="cancel">
          {{ $t('button.close') }}
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <v-card
          class="mt-5"
          outlined
        >
          <v-card-title>利用履歴</v-card-title>
          <v-card-text>
            <ExchangeTable
              :headers="headers"
              :items="itemsPerPage"
              :loading="loading"
              :options="options"
              @update:options="changeOptions"
            >
              <template #top>
                <v-row class="px-4">
                  <ExchangeDetailDialog
                    v-model="detail.show"
                    :exchange="detail.exchange"
                    :read-only="detail.readOnly"
                    :user-id="userId"
                  />
                  <v-spacer />
                  <v-btn
                    :disabled="loading"
                    :loading="loading"
                    color="primary"
                    type="button"
                    @click="showDetail()"
                  >
                    {{ $t('button.new') }}
                  </v-btn>
                </v-row>
                <v-row class="px-4">
                  <v-spacer />
                  <v-checkbox
                    v-model="withDeleted"
                    hide-details
                    label="削除済みも表示"
                    @change="onChangeWithDeleted"
                  />
                </v-row>
              </template>
              <template #item="{ item }">
                <tr
                  v-if="$vuetify.breakpoint.mobile"
                  class="v-data-table__mobile-table-row cell--clickable"
                  @click="showDetail(item)"
                >
                  <td class="v-data-table__mobile-row">
                    <div class="v-data-table__mobile-row__header">
                      {{ headers[0].text }}
                    </div>
                    <div class="v-data-table__mobile-row__cell">
                      {{ item?.created_at ? $moment(item?.created_at).format('llll') : '' }}
                    </div>
                  </td>
                  <td class="v-data-table__mobile-row">
                    <div class="v-data-table__mobile-row__header">
                      {{ headers[1].text }}
                    </div>
                    <div class="v-data-table__mobile-row__cell">
                      {{ item?.point }}
                    </div>
                  </td>
                  <td class="v-data-table__mobile-row">
                    <div class="v-data-table__mobile-row__header">
                      {{ headers[2].text }}
                    </div>
                    <div class="v-data-table__mobile-row__cell">
                      {{ item?.memo }}
                    </div>
                  </td>
                </tr>
                <tr
                  v-else
                  class="cell--clickable"
                  @click="showDetail(item)"
                >
                  <td class="text-start">
                    <v-chip
                      v-if="item?.deleted_at"
                      class="mr-1"
                      filter
                      small
                    >
                      削除済
                    </v-chip>
                    {{ item?.created_at ? $moment(item?.created_at).format('llll') : '' }}
                  </td>
                  <td class="text-end pr-12">
                    {{ item?.point }}
                  </td>
                  <td class="text-start flex-grow-1 flex-shrink-0">
                    {{ item?.memo }}
                  </td>
                </tr>
              </template>
            </ExchangeTable>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import MyDataTable from '@/components/atoms/MyDataTable.vue'
import Exchange from '@/store/models/Exchange'
import { User } from '@/store/models/Person'
import { Collection, Item } from '@vuex-orm/core'
import { DataOptions, DataTableHeader } from 'vuetify'
import { SearchCondition, SearchSort } from '@/utils/http'
import { FetchResult } from '@/store/models/Model'
import ExchangeDetailDialog from '@/components/organisms/exchanges/ExchangeDetailDialog.vue'
import EditDepartmentDialog from '@/components/organisms/departments/EditDepartmentDialog.vue'
import DeleteDepartmentDialog from '@/components/organisms/departments/DeleteDepartmentDialog.vue'

const ExchangeTable = MyDataTable<Exchange>
@Component({
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    DeleteDepartmentDialog, EditDepartmentDialog,
    ExchangeDetailDialog,
    ExchangeTable,
  },
})
export default class ExchangeHistoryDialog extends Vue {
  @Prop({ default: null })
  readonly value!: boolean
  @Prop({ default: null })
  readonly userId!: number

  loading = false
  totalCount = 0
  options: Partial<DataOptions> = {
    sortBy: ['created_at'],
    sortDesc: [true],
  }

  detail: {
    readOnly?: boolean
    exchange?: Item<Exchange>
    show: boolean
  } = {
    show: false,
  }

  withDeleted = false
  private loaded = false

  get user(): Item<User> {
    return User.find(this.userId)
  }

  get headers(): DataTableHeader[] {
    return [
      {
        text: this.$t('createdAt') as string,
        value: 'created_at',
      },
      {
        text: this.$t('exchange.point') as string,
        value: 'point',
      },
      {
        text: this.$t('exchange.memo') as string,
        value: 'memo',
      },
    ]
  }

  get itemsPerPage(): Collection<Exchange> {
    const { page, itemsPerPage } = this.options
    if (itemsPerPage > 0) {
      return this.exchanges.slice((page - 1) * itemsPerPage, page * itemsPerPage)
    }
    return this.exchanges
  }

  get exchanges(): Collection<Exchange> {
    if (!this.user) return []
    const { sortBy = [], sortDesc = [] } = this.options
    const query = Exchange.query()
    query.where('user_id', this.user.id)
    if (!this.withDeleted) {
      query.where((item: Item<Exchange>) => !item.deleted_at)
    }
    sortBy.forEach((key, index) => {
      query.orderBy(key, sortDesc[index] ? 'desc' : 'asc')
    })
    query.orderBy('id', 'desc')
    return query.get()
  }

  get dialog(): boolean {
    return this.value
  }

  set dialog(value: boolean) {
    if (this.value !== value) {
      this.input(value)
    }
  }

  @Watch('userId')
  watchUserId() {
    User.fetch(this.userId)
  }

  @Emit()
  input(value: boolean): boolean {
    return value
  }

  @Watch('value')
  async watchValue(): Promise<void> {
    if (!this.value) {
      return
    }
    this.$validator.reset()
    this.loading = true
    await this.fetch()
    this.loading = false
  }

  cancel(): void {
    this.dialog = false
  }

  async changeOptions(value: {
    sortBy: string[]
    sortDesc: boolean[]
    page: number
    itemsPerPage: number
  }): Promise<void> {
    if (!this.loaded) {
      return
    }
    this.options = value
    this.loading = true
    await this.fetch()
    this.loading = false
  }

  showDetail(exchange?: Item<Exchange>) {
    this.detail = {
      exchange,
      readOnly: !!exchange?.deleted_at,
      show: true,
    }
  }

  onChangeWithDeleted() {
    this.fetch()
  }

  private async fetch(): Promise<void> {
    if (!this.user) return
    const { sortBy = [], sortDesc = [], page = 1, itemsPerPage = 25 } = this.options
    const limit = itemsPerPage > 0 ? itemsPerPage : 25
    const sort: SearchSort = []
    sortBy.forEach((key, index) => {
      sort.push({ name: key, order: sortDesc[index] ? 'desc' : 'asc' })
    })
    sort.push({ name: 'id', order: 'desc' })
    const params = Object.assign({}, new SearchCondition({ page, limit, sort }).toRansackParams(), {
      'q[user_id_eq]': this.user.id,
      'with_deleted': Number(this.withDeleted),
    })
    const { totalCount }: FetchResult = await (() => {
      if (itemsPerPage === -1) {
        return Exchange.fetchAll({ useCache: false, params })
      }
      return Exchange.fetch({ useCache: false, params })
    })()
    this.totalCount = totalCount
    this.loaded = true
  }
}
</script>
