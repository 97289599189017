/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
import { Collection, Fields, Item } from '@vuex-orm/core'
import Feeling from '@/store/models/Feeling'
import Category from '@/store/models/Category'
import GiftCategory from '@/store/models/GiftCategory'
import Reaction from '@/store/models/Reaction'
import Comment from '@/store/models/Comment'
import { User } from '@/store/models/Person'
import File from '@/store/models/File'
import Model from '@/store/models/Model'
import { getDisplayName } from '@/utils/user'
import Me from '@/store/models/Me'
import _isNil from 'lodash/isNil'

export default class Gift extends Model {
  static entity = 'Gift'
  static _endpoint = 'gifts'
  static readonly DEFAULT_SENDER_ID = -1
  id!: number
  point!: number
  message!: string
  edited: boolean
  accepted_at: string
  recipient_id: number
  recipient: Item<User>
  recipient_feeling_id: number
  recipient_feeling: Item<Feeling>
  sender_id: number
  sender: Item<User>
  sender_feeling_id: number
  sender_feeling: Item<Feeling>
  categories: Collection<Category>
  reactions: Collection<Reaction>
  comments: Collection<Comment>
  created_at?: string
  updated_at?: string
  deleted_at?: string
  file_ids: number[]
  files: Collection<File>
  adjustable_point_range: [number, number]
  cancelable: boolean

  get recipientName(): string {
    return getDisplayName(this.recipient)
  }

  get senderName(): string {
    return getDisplayName(this.sender)
  }

  static fields(): Fields {
    return {
      id: this.number(null),
      point: this.number(null),
      message: this.string(null),
      edited: this.attr(false),
      accepted_at: this.attr(null),
      recipient_id: this.number(null),
      recipient: this.belongsTo(User, 'recipient_id'),
      recipient_feeling_id: this.attr(null),
      recipient_feeling: this.belongsTo(Feeling, 'recipient_feeling_id'),
      sender_id: this.number(null),
      sender: this.belongsTo(User, 'sender_id'),
      sender_feeling_id: this.attr(null),
      sender_feeling: this.belongsTo(Feeling, 'sender_feeling_id'),
      categories: this.belongsToMany(Category, GiftCategory, 'gift_id', 'category_id'),
      reactions: this.morphMany(Reaction, 'reactable_id', 'reactable_type'),
      comments: this.morphMany(Comment, 'commentable_id', 'commentable_type'),
      file_ids: this.attr([]),
      files: this.hasManyBy(File, 'file_ids'),
      adjustable_point_range: this.attr([]),
      cancelable: this.boolean(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      deleted_at: this.attr(null),
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async create({ data: _data }): Promise<any> {
    const data = await this.normalizeData(_data)
    return this.dispatch('create', { data })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async insert({ data: _data }): Promise<any> {
    const data = await this.normalizeData(_data)
    return this.dispatch('insert', { data })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async update({ data: _data }): Promise<any> {
    const data = await this.normalizeData(_data)
    return this.dispatch('update', { data })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static async insertOrUpdate({ data: _data }): Promise<any> {
    const data = await this.normalizeData(_data)
    data.forEach((item: Gift) => GiftCategory.delete(({ gift_id }) => gift_id === item.id))
    return this.dispatch('insertOrUpdate', { data })
  }

  static format(gift: Item<Gift>): Item<Gift> {
    const current = Gift.getters('find')(gift.id)

    if (gift.recipient?.feeling) {
      gift.recipient_feeling = gift.recipient.feeling
      delete gift.recipient.feeling
    }

    if (gift.sender?.feeling) {
      gift.sender_feeling = gift.sender.feeling
      delete gift.sender.feeling
    } else {
      // HACK: 0は利用されているので-1で設定する
      gift.sender_id = Gift.DEFAULT_SENDER_ID
    }

    if (current?.accepted_at) {
      gift.accepted_at = current.accepted_at
    }

    gift.reactions = gift.reactions.map(Reaction.format)
    gift.comments = gift.comments.map(Comment.format)

    return gift
  }

  private static async normalizeData(data: Item<Gift> | Collection<Gift>): Promise<Collection<Gift>> {
    if (_isNil(data)) return []

    await Me.load()
    const currentUser = Me.query().first()

    const items = Array.isArray(data) ? data : [data]

    return items.map((item: Gift) => this.normalizeItem(item, currentUser))
  }

  private static normalizeItem(item: Gift, currentUser: User): Gift {
    const storedItem = this.find(item.id)

    if (_isNil(storedItem)) {
      return this.format(item)
    }

    if (_isNil(item?.deleted_at)) {
      return this.format(item)
    }

    if (currentUser.id !== item.sender_id && !currentUser.admin) {
      return this.format(item)
    }

    item.message = storedItem.message
    return this.format(item)
  }
}
