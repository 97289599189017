<template>
  <v-container
    v-visibility-change="visibilityChange"
    class="p-timeline fill-height flex-column align"
    fluid
  >
    <div class="p-timeline__content">
      <v-tabs
        ref="tabBar"
        :value="currentTab"
        align-with-title
        background-color="grey lighten-4"
        class="p-timeline__tabs"
        show-arrows
        @change="changeTab"
      >
        <v-tab
          v-for="(tab, idx) in tabs"
          :key="`tab-${idx}`"
          :disabled="loading && timelines.length === 0"
          :href="`#${tab.key}`"
          @click="resetTag(tab.key)"
        >
          {{ tab.name }}
        </v-tab>
      </v-tabs>
      <div class="d-flex">
        <v-slide-x-transition>
          <v-list
            v-if="showTweetTag"
            v-show="tabInfo.showTweetTags"
            :style="tweetTagsStyle"
            class="p-timeline__tweetTags"
            color="grey lighten-4"
          >
            <v-list-item-group
              v-if="tweetTags.length >0"
              :mandatory="$vuetify.breakpoint.smAndUp"
              :value="currentTag"
              color="indigo"
            >
              <template v-for="item of tweetTags">
                <v-list-item
                  :key="`tag-${item.id}`"
                  :href="`#${item.name}`"
                  :value="item.name"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                  <span v-text="`(${item.taggings_count})`" />
                </v-list-item>
              </template>
            </v-list-item-group>
            <v-list-item v-if="!loading && tweetTags.length === 0">
              <v-list-item-content>
                <v-list-item-title>タグがありません。</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-slide-x-transition>
        <div
          v-if="showTimeline"
          :style="mainStyle"
          class="p-timeline__main"
        >
          <div
            v-show="tabInfo.canPostTweet"
            class="p-timeline__tweetForm"
          >
            <PostTweet />
          </div>
          <InfiniteScroll
            :loading="loading"
            two-line
            @infinite="fetch"
          >
            <template v-for="item of timelines">
              <TweetListItem
                v-if="item.timelinable_type === 'Tweet'"
                :key="`timeline-${item.id}`"
                :commentable="true"
                :value="item.timelinable"
              />
              <GiftListItem
                v-else-if="item.timelinable_type === 'Gift'"
                :key="`timeline-${item.id}`"
                :commentable="true"
                :value="item.timelinable"
              />
              <v-divider :key="`divider-${item.id}`" />
            </template>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  </v-container>
</template>
<script lang="ts">
import Vue from 'vue'
import PostTweet from '@/components/organisms/tweets/PostTweet.vue'
import Component from 'vue-class-component'
import { Collection, Query, Record } from '@vuex-orm/core'
import Timeline from '@/store/models/Timeline'
import GiftListItem from '@/components/organisms/gifts/list/GiftListItem.vue'
import { RansackParams, SearchCondition } from '@/utils/http'
import TweetListItem from '@/components/organisms/tweets/TweetListItem.vue'
import InfiniteScroll from '@/components/organisms/InfiniteScroll.vue'
import _isNil from 'lodash/isNil'
import visibilityChange from '@/directives/visibilityChange'
import OverstayingAlert from '@/components/organisms/tenants/OverstayingAlert.vue'
import { TweetTag } from '@/store/models/Tag'
import { mdiDotsHorizontal, mdiMenuDown } from '@mdi/js'
import { Watch } from 'vue-property-decorator'
import { FetchOptions, FetchResult } from '@/store/models/Model'
import Me from '@/store/models/Me'

type TabType = 'All' | 'Gift' | 'Tweet'
type TabKey = 'all' | 'followings' | 'thanks' | 'tweet' | 'tweet-tag'
type TabInfo = {
  key: TabKey
  name: string
  type: TabType
  canPostTweet: boolean
  showTweetTags: boolean
}
type TLSearchCondition = Partial<{
  id_null: boolean
  user_id_or_sender_id_in: number[]
  timelinable_type_eq: TabType
  timelinable_tags_name_in: string[]
}>
@Component({
  components: {
    GiftListItem,
    TweetListItem,
    PostTweet,
    InfiniteScroll,
    OverstayingAlert,
  },
  directives: {
    visibilityChange,
  },
  $_veeValidate: {
    validator: 'new',
  },
  metaInfo() {
    return {
      title: 'タイムライン',
    }
  },
})
export default class extends Vue {
  $refs: {
    tabBar: Vue
  }
  readonly model = Timeline
  readonly icons = {
    mdiDotsHorizontal,
    mdiMenuDown,
  }
  isEditing = false
  readonly tabs: TabInfo[] = [
    {
      key: 'all',
      name: '全て',
      type: 'All',
      canPostTweet: true,
      showTweetTags: false,
    },
    {
      key: 'followings',
      name: 'フォロー中',
      type: 'All',
      canPostTweet: true,
      showTweetTags: false,
    },
    {
      key: 'thanks',
      name: '感謝',
      type: 'Gift',
      canPostTweet: true,
      showTweetTags: false,
    },
    {
      key: 'tweet',
      name: 'つぶやき',
      type: 'Tweet',
      canPostTweet: true,
      showTweetTags: false,
    },
    {
      key: 'tweet-tag',
      name: 'つぶやきをタグから探す',
      type: 'Tweet',
      canPostTweet: true,
      showTweetTags: true,
    },
  ]
  currentTab: TabKey = this.tabs[0].key
  loading = true
  readonly limit = 25
  page = 0
  currentTag = ''
  tabBar: Element = null
  tabBarHeight = 0

  get isAdminMode(): boolean {
    return this.$store.state.screen.mode === 'admin'
  }

  get currentUser(): Record {
    return Me.query().first()
  }

  get showTweetTag(): boolean {
    if (!this.$vuetify.breakpoint.xsOnly) return true
    return !this.currentTag
  }

  get showTimeline(): boolean {
    if (!this.tabInfo.showTweetTags) return true
    if (!this.$vuetify.breakpoint.xsOnly) return true
    return this.currentTag !== ''
  }

  get tabInfo(): TabInfo {
    const tabInfo = this.tabs.find(({ key }) => key === this.currentTab)
    return tabInfo ? tabInfo : this.tabs[0]
  }

  get searchParams(): Partial<RansackParams> {
    const params = {
      page: this.page + 1,
      limit: this.limit,
    }
    let condition: TLSearchCondition = {}
    if (this.tabInfo.type !== 'All') {
      condition['timelinable_type_eq'] = this.tabInfo.type
    }
    condition = this.createConditionToSearchByFollowings(condition)
    condition = this.createConditionToSearchByTags(condition)
    return new SearchCondition({
      condition,
      ...params,
    }).toRansackParams()
  }

  get exists(): boolean {
    return !_isNil(this.timelines) && this.timelines.length > 0
  }

  get timelines(): Collection<Timeline> {
    let query = Timeline.query()
      .with([
        'timelinable.recipient',
        'timelinable.recipient_feeling',
        'timelinable.sender',
        'timelinable.sender_feeling',
        'timelinable.categories',
        'timelinable.user',
        'timelinable.feeling',
        'timelinable.reactions',
        'timelinable.reactions.user',
        'timelinable.deleted_at',
      ])
    if (this.tabInfo.type !== 'All') {
      query = query.where('timelinable_type', this.tabInfo.type)
    }
    query = this.whereByTags(query)
    query = this.whereByFollowings(query)
    query = query.orderBy('created_at', 'desc')
    return query.get()
  }

  get tweetTags(): Record[] {
    return TweetTag.query().orderBy('taggings_count', 'desc').all()
  }

  get mainStyle(): Partial<CSSStyleDeclaration> {
    const style: Partial<CSSStyleDeclaration> = {
      overflowY: 'auto',
    }
    const height = `calc(100vh - ${this.tabBarHeight}px - ${this.$vuetify.application.top}px)`
    style.maxHeight = height
    style.minHeight = height
    return style
  }

  get tweetTagsStyle(): Partial<CSSStyleDeclaration> {
    const style: Partial<CSSStyleDeclaration> = {
      overflowY: 'auto',
    }
    const height = `calc(100vh - ${this.tabBarHeight}px - ${this.$vuetify.application.top}px)`
    style.maxHeight = height
    style.minHeight = height
    if (!this.$vuetify.breakpoint.xsOnly) {
      style.minWidth = '256px'
      style.maxWidth = '256px'
    }
    return style
  }

  createConditionToSearchByTags(condition: Partial<TLSearchCondition>): TLSearchCondition {
    if (!this.tabInfo.showTweetTags) return condition
    if (this.currentTag === '') return condition
    return {
      ...condition,
      'timelinable_tags_name_in': [this.currentTag],
    }
  }

  createConditionToSearchByFollowings(condition: Partial<TLSearchCondition>): TLSearchCondition {
    if (this.tabInfo.key !== 'followings') return condition
    if (this.currentUser.followings.length === 0) {
      return {
        ...condition,
        'id_null': true,
      }
    }
    return {
      ...condition,
      'user_id_or_sender_id_in': this.currentUser.followings.map((item) => item.id),
    }
  }

  whereByTags(query: Query<Timeline>): Query<Timeline> {
    if (!this.tabInfo.showTweetTags) return query
    if (this.currentTag === '') return query
    return query.whereHas('timelinable', q1 => {
      q1.where(({ tags }) => _isNil(tags) ? false : tags.includes(this.currentTag))
    })
  }

  whereByFollowings(query: Query<Timeline>): Query<Timeline> {
    if (this.tabInfo.key !== 'followings') return query
    if (this.currentUser.followings.length === 0) {
      return query.where('id', null)
    }
    const followingIds = this.currentUser.followings.map(({ id }) => id)
    const byFollowingIds = (q: Query) => q.where(({ id }) => followingIds.includes(id))
    const hasUser = (relation: string) => (_, q) => q.whereHas(relation, byFollowingIds)
    query.where((_, q1) => q1.whereHas('timelinable', q2 => q2
      .where(hasUser('sender')).orWhere(hasUser('recipient')).orWhere(hasUser('user'))))
    return query
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSizeDependencies(item): any[] {
    if (_isNil(item)) return
    if (_isNil(item.timelinable)) return
    const sizeDependencies = [item.timelinable.comments, item.timelinable.files, item.timelinable.reactions]
    if (item['timelinable_type'] === 'tweets') {
      sizeDependencies.push(item.timelinable.text)
    }
    if (item['timelinable_type'] === 'gifts') {
      sizeDependencies.push(item.timelinable.message)
    }
    return sizeDependencies
  }

  mounted(): void {
    this.tabBar = this.$refs.tabBar.$el
    this.routeChanged()
    this.onResize()
    window.addEventListener('resize', this.onResize)
  }

  beforeDestroy(): void {
    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize)
  }

  onResize(): void {
    const rect = this.tabBar.getBoundingClientRect()
    this.tabBarHeight = rect.height
  }

  resetTag(tab): void {
    if (this.currentTag === '') return
    if (this.currentTab !== tab) return
    if (!this.$vuetify.breakpoint.xsOnly) return
    this.currentTag = null
    this.$router.replace({ query: { tab }, hash: '' })
  }

  changeTab(tab: string): void {
    this.$router.push({ query: { tab } })
  }

  async fetch({ useCache } = { useCache: true }): Promise<void> {
    this.loading = true
    try {
      await Timeline.fetch({
        params: this.searchParams,
        useCache,
      })
      this.page = Math.floor(this.timelines.length / this.limit)
    } finally {
      this.loading = false
    }
  }

  async fetchTweetTag({ useCache } = { useCache: false }): Promise<void> {
    const options: Partial<FetchOptions> = {
      params: {
        page: 1,
        limit: 10,
      },
      useCache,
    }
    const { totalCount } = await TweetTag.fetch(options)
    const pageCount = Math.ceil(totalCount / options.params.limit)
    const requests: Promise<FetchResult>[] = []
    for (options.params.page = 2; options.params.page <= pageCount; options.params.page++) {
      requests.push(TweetTag.fetch(options))
    }
    if (requests.length > 0) {
      Promise.all(requests)
    }
  }

  visibilityChange(visible): void {
    if (visible && !this.loading) {
      this.page = 0
      this.fetch({ useCache: false })
    }
  }

  @Watch('$route')
  async routeChanged(): Promise<void> {
    const { tab } = this.$route.query
    this.currentTab = _isNil(tab) ? this.tabs[0].key : (tab as TabKey)
    this.isEditing = false
    this.page = 0
    await this.changeTag()
    this.fetch({ useCache: false })
  }

  async changeTag(): Promise<void> {
    if (!this.tabInfo.showTweetTags) return
    const tag = decodeURIComponent(this.$route.hash)
    await this.fetchTweetTag({ useCache: true })
    if (this.tweetTags.length === 0) {
      this.currentTag = ''
      return
    }
    this.currentTag = tag.replace(/^#/, '')
    if (this.$vuetify.breakpoint.smAndUp) {
      this.currentTag = this.currentTag || this.tweetTags[0].name
    }
  }
}
</script>
<style lang="scss" scoped>
.p-timeline {
  position: relative;
  z-index: 0;
  flex: 5;
  padding: 0;

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 100%;
    padding: 0;
    overflow-y: auto;
  }

  &__item {
    cursor: pointer;
  }

  &__tabs {
    z-index: 3;
    flex-grow: 0;
    width: 100%;
  }

  &__main {
    flex: 1 1 100%;
    min-width: 0;
    overflow: hidden;
  }

  &__tweetTags {
    flex: 1 1 25%;
    overflow: hidden;
  }

  &__tweetForm {
    z-index: 4;
    width: 100%;
    padding: 4px 16px;
    background-color: #f5f5f5;
  }
}
</style>
