<template>
  <v-card>
    <v-card-title class="text-h5">
      メンバー一括招待
    </v-card-title>
    <v-card-text v-if="messages.length >0">
      <v-alert
        :value="messages.length >0"
        color="error"
        dark
        dismissible
      >
        <div
          v-for="(message, index) in messages"
          :key="index"
        >
          {{ message }}
        </div>
      </v-alert>
    </v-card-text>
    <v-card-text v-if="overflow">
      <v-alert
        v-model="overflow"
        color="warning"
        dismissible
        outlined
        prominent
        text
      >
        <v-row
          align="center"
          class="ma-0 pa-0"
        >
          <v-col class="grow">
            {{ $t('message.user.overflow') }}
          </v-col>
          <v-col class="shrink">
            <v-btn
              :to="{ name: 'agreements', hash: '#plan' }"
              color="warning"
            >
              {{ $t('button.editPlan') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn @click="download">
        <v-icon left>
          {{ icons.mdiDownload }}
        </v-icon>
        雛形ダウンロード
      </v-btn>
      <v-btn
        :loading="uploading"
        color="primary"
        @click="$refs.csvFile.click()"
      >
        <v-icon left>
          {{ icons.mdiUpload }}
        </v-icon>
        ファイルアップロード
        <input
          ref="csvFile"
          :value="file"
          accept="text/csv"
          type="file"
          @change="select"
        >
      </v-btn>
    </v-card-actions>
    <v-card-actions v-if="!!cancelLabel || !!backLabel || !!skipLabel">
      <v-btn
        v-if="cancelLabel"
        @click="cancel"
      >
        {{ cancelLabel }}
      </v-btn>
      <v-btn
        v-if="backLabel"
        @click="back"
      >
        {{ backLabel }}
      </v-btn>
      <v-spacer />
      <v-btn
        v-if="skipLabel"
        @click="skip"
      >
        {{ skipLabel }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import _isNil from 'lodash/isNil'
import { Emit, Prop } from 'vue-property-decorator'
import { downloadCsv } from '@/utils/download'
import { mdiDownload, mdiEmail, mdiUpload } from '@mdi/js'
import notification from '@/utils/notification'
import _indexOf from 'lodash/indexOf'

@Component({
  $_veeValidate: {
    validator: 'new',
  },
})
export default class InviteBulkMemberComponent extends Vue {
  $refs!: {
    csvFile: HTMLInputElement
  }
  @Prop({ default: null })
  readonly inviteLabel?: string
  @Prop({ default: null })
  readonly skipLabel?: string
  @Prop({ default: null })
  readonly backLabel?: string
  @Prop({ default: null })
  readonly cancelLabel?: string
  readonly icons = {
    mdiDownload,
    mdiUpload,
    mdiEmail,
  }
  messages = []
  overflow = false
  file = null
  uploading = false

  download(): void {
    const example = [
      'Eメール,名前,部署(任意）,社員番号(任意）,タグ1(任意）,タグ2(任意）,タグ3(任意）,タグ4(任意）,タグ5(任意）,タグ6(任意）',
    ]
    downloadCsv(example.join(String.fromCharCode(13, 10)), 'users.csv')
  }

  select(event: Event): void {
    const { files } = event.target as HTMLInputElement
    if (files.length === 0 || _isNil(files[0])) {
      return
    }
    this.upload(files[0])
  }

  async upload(file: File): Promise<void> {
    this.uploading = true
    this.messages = []
    const formData = new FormData()
    formData.append('users', file)
    this.$http
      .post('invitations/bulk', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then(() => {
        notification.success(this.$t('message.invited') as string)
        this.invited()
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => {
        if (err.status === 422) {
          if (_indexOf(err.response.data, 'User::OverflowError') !== -1) {
            this.overflow = true
          } else {
            this.messages = err.response.data
          }
          return
        }
        return Promise.reject(err)
      })
      .finally(async () => {
        this.uploading = false
        this.file = null
      })
  }

  @Emit('invited')
  invited(): void {
    return void 0
  }

  @Emit('skip')
  skip(): void {
    return void 0
  }

  @Emit('back')
  back(): void {
    return void 0
  }

  @Emit('cancel')
  cancel(): void {
    return void 0
  }
}
</script>
<style lang="scss" scoped>
input[type='file'] {
  width: 0;
  max-width: 0;
  opacity: 0;
}
</style>
