<template>
  <InfiniteScroll
    v-visibility-change="visibilityChange"
    :loading="loading"
    two-line
    @infinite="fetch"
  >
    <template v-for="item of takes">
      <TakeListItem
        :key="item.id"
        :commentable="true"
        :value="item"
      />
      <v-divider :key="`divider-${item.id}`" />
    </template>
    <v-list-item v-if="!loading && !exists">
      <v-list-item-content>
        <v-container
          class="d-flex justify"
          fluid
        >
          <div>
            <template v-if="isMine">
              困っている仲間を助けると感謝してもらえるかもしれませんよ。
            </template>
            <template v-else>
              <strong>{{ _.get(user, 'name') }}</strong>
              さんに日頃の感謝をおくりましょう。
            </template>
          </div>
        </v-container>
      </v-list-item-content>
    </v-list-item>
  </InfiniteScroll>
</template>
<script lang="ts">
import Vue from 'vue'
import { defaultFeeling } from '@/store/models/Feeling'
import _get from 'lodash/get'
import Gift from '@/store/models/Gift'
import Component from 'vue-class-component'
import Me from '@/store/models/Me'
import { Record } from '@vuex-orm/core'
import { User } from '@/store/models/Person'
import TakeListItem from '@/components/organisms/gifts/list/TakeListItem.vue'
import InfiniteScroll from '@/components/organisms/InfiniteScroll.vue'
import _isNil from 'lodash/isNil'
import visibilityChange from '@/directives/visibilityChange'
import { Watch } from 'vue-property-decorator'
import { RansackParams } from '@/utils/http'

@Component({
  components: {
    TakeListItem,
    InfiniteScroll,
  },
  directives: {
    visibilityChange,
  },
  metaInfo() {
    return {
      title: 'もらった',
    }
  },
})
export default class extends Vue {
  readonly defaultFeeling = defaultFeeling
  loading = true
  readonly limit = 25
  page = 0

  get currentUser(): Record {
    return Me.query().first()
  }

  get user(): Record {
    return User.query().find(this.userId)
  }

  get userId(): number {
    return +this.$route.params.id
  }

  get isMine(): boolean {
    return this.userId === _get(this.currentUser, 'id')
  }

  get exists(): boolean {
    return !_isNil(this.takes) && this.takes.length > 0
  }

  get takes(): Record[] {
    return Gift.query()
      .with(['recipient', 'recipient_feeling', 'sender', 'sender_feeling', 'categories'])
      .where('recipient_id', this.userId)
      .orderBy('created_at', 'desc')
      .get()
  }

  get searchParams(): Partial<RansackParams> {
    return {
      page: this.page + 1,
      limit: this.limit,
    }
  }

  mounted(): void {
    this.page = 0
    this.fetch()
  }

  @Watch('userId')
  changeUserId(): void {
    this.page = 0
    this.fetch()
  }

  fetch({ useCache } = { useCache: true }): void {
    this.loading = true
    Gift.fetch({
      url: ['users', this.userId, 'takes'].join('/'),
      params: this.searchParams,
      useCache,
    })
      .then(() => {
        this.page = Math.floor(this.takes.length / this.limit)
      })
      .finally(() => {
        this.loading = false
      })
  }

  visibilityChange(visible): void {
    if (visible && !this.loading) {
      this.page = 0
      this.fetch({ useCache: false })
    }
  }
}
</script>
