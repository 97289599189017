import _isNil from 'lodash/isNil'
import Person from '@/store/models/Person'
import { Item } from '@vuex-orm/core'

export function getDisplayName<T extends Person>(user: Item<T>): string {
  if (_isNil(user)) {
    return '削除メンバー'
  }

  if (!_isNil(user.deleted_at)) {
    return `${user.name}(利用停止中)`
  }
  if (!_isNil(user.invitation_sent_at) && _isNil(user.invitation_accepted_at)) {
    return `${user.name}(招待中)`
  }
  return user.name
}
