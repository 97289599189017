<template>
  <v-icon
    v-if="value"
    :color="color"
    small
  >
    {{ icons.mdiCheckboxMarkedCircle }}
  </v-icon>
  <v-icon
    v-else
    :color="'#d3d4d3'"
    small
  >
    {{ icons.mdiCircleOutline }}
  </v-icon>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { mdiCheckboxMarkedCircle, mdiCircleOutline } from '@mdi/js'

@Component
export default class extends Vue {
  @Prop({ default: false })
  readonly value!: boolean

  @Prop({ default: '#3a9bfc' })
  readonly color!: string

  readonly icons = {
    mdiCheckboxMarkedCircle,
    mdiCircleOutline,
  }
}
</script>
