<template>
  <DeleteDialog
    :message="message"
    :title="`${tweet?.user?.name} さんのつぶやきを復元する`"
    :value="value"
    @input="onInputRestoreDialog"
    @click:delete="onClickRestore"
  />
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { Item } from '@vuex-orm/core'
import notification from '@/utils/notification'
import DeleteDialog from '@/components/atoms/DeleteDialog.vue'
import Me from '@/store/models/Me'
import Tweet from '@/store/models/Tweet'

@Component({
  components: { DeleteDialog },
})
export default class extends Vue {
  @Prop({ default: null })
  readonly value?: boolean
  @Prop({ default: null })
  readonly tweet?: Item<Tweet>

  get message(): string {
    return this.$marked(this.tweet?.text)
  }

  @Emit()
  input(value: boolean): boolean {
    return value
  }

  async onClickRestore(): Promise<void> {
    await this.$http.delete(['tweets', this.tweet?.id, 'deletion'].join('/'))
    const { data } = await this.$http.get(['tweets', this.tweet?.id].join('/'))
    await Tweet.insertOrUpdate({ data })
    await Me.fetch({ useCache: false })
    notification.success(this.$t('message.restored') as string)
  }

  onInputRestoreDialog(value: boolean): void {
    this.input(value)
  }
}
</script>
