<template>
  <v-app-bar
    app
    fixed
  >
    <v-btn
      :disabled="$route.name === 'timeline'"
      class="pa-0"
      link
      max-height="36px"
      max-width="36px"
      min-height="36px"
      min-width="36px"
      text
      @click.prevent.stop="historyBack"
    >
      <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
    </v-btn>
    <v-btn
      class="hidden-md-and-up"
      icon
      @click="clickMenu"
    >
      <v-icon>{{ icons.mdiMenu }}</v-icon>
    </v-btn>
    <v-spacer />
    <div class="hidden-xs-only mr-3">
      <v-icon>
        {{ icons.mdiAccountOutline }}
      </v-icon>
      {{ numberOfMembers }}
    </div>
    <v-divider
      class="hidden-xs-only mx-3"
      inset
      vertical
    />
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn
          :disabled="_.get(tenant, 'plan') === 'simple'"
          :to="{ name: 'award' }"
          class="mr-3"
          icon
          max-height="36px"
          max-width="36px"
          @click="on.mouseleave"
          @mouseenter="on.mouseenter"
          @mouseleave="on.mouseleave"
        >
          <v-icon>
            {{ icons.mdiTrophyAward }}
          </v-icon>
        </v-btn>
      </template>
      <span>表彰</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn
          :disabled="_.get(tenant, 'plan') === 'simple'"
          :to="{ name: 'dashboard' }"
          class="mr-3"
          icon
          max-height="36px"
          max-width="36px"
          @click="on.mouseleave"
          @mouseenter="on.mouseenter"
          @mouseleave="on.mouseleave"
        >
          <v-icon>
            {{ icons.mdiGraph }}
          </v-icon>
        </v-btn>
      </template>
      <span>相関図</span>
    </v-tooltip>
    <v-badge
      :value="numberOfTakes >0"
      class="mr-3"
      overlap
      style="z-index: 9"
    >
      <template #badge>
        <span class="pa-0">{{ toBadgeString(numberOfTakes) }}</span>
      </template>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            :to="{
              name: 'member/takes',
              params: { id: _.get(currentUser, 'id') },
            }"
            icon
            max-height="36px"
            max-width="36px"
            @click="on.mouseleave"
            @mouseenter="on.mouseenter"
            @mouseleave="on.mouseleave"
          >
            <v-icon>
              {{ icons.mdiGift }}
            </v-icon>
          </v-btn>
        </template>
        <span>もらった感謝</span>
      </v-tooltip>
    </v-badge>
    <v-badge
      :value="numberOfNotices >0"
      class="mr-3"
      overlap
      style="z-index: 8"
    >
      <template #badge>
        <span class="pa-0">{{ toBadgeString(numberOfNotices) }}</span>
      </template>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            icon
            max-height="36px"
            max-width="36px"
            @click="
              on.mouseleave()
              showNoticeDialog()
            "
            @mouseenter="on.mouseenter"
            @mouseleave="on.mouseleave"
          >
            <v-icon>
              {{ icons.mdiInformationOutline }}
            </v-icon>
          </v-btn>
        </template>
        <span>お知らせ</span>
      </v-tooltip>
    </v-badge>
    <v-tooltip
      v-if="_.get(currentUser, 'admin')"
      bottom
    >
      <template #activator="{ on }">
        <v-btn
          class="mr-3"
          icon
          max-height="36px"
          max-width="36px"
          @click="on.mouseleave();toggleScreenMode()"
          @mouseenter="on.mouseenter"
          @mouseleave="on.mouseleave"
        >
          <v-icon
            :color="isAdminMode ? 'error' : ''"
          >
            {{ icons.mdiAccountSync }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ isAdminMode ? '管理者モードをやめる' : '管理者モード' }}</span>
    </v-tooltip>
    <Avatar
      :user="currentUser"
      class="mr-3"
      size="36px"
      style="z-index: 7"
    />
    <strong class="hidden-xs-only">{{ _.get(currentUser, 'name') }}</strong>
    <v-menu :nudge-width="100">
      <template #activator="{ on }">
        <v-btn
          color="primary"
          icon
          text
          v-on="on"
        >
          <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="showEditFeelingDialog()">
          <v-list-item-title>気分変更</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="pushManager.isSupported"
          @click="showNotifySettingsDialog"
        >
          <v-list-item-title>プッシュ通知設定</v-list-item-title>
        </v-list-item>
        <v-divider />
        <template v-if="communities.length >0">
          <v-subheader>コミュニティー活動</v-subheader>
          <v-list-item
            v-for="community in communities"
            :key="`community-${community.id}`"
            @click="moveToCommunity(community)"
          >
            <v-list-item-title>{{ community.name }}</v-list-item-title>
          </v-list-item>
          <v-divider />
        </template>
        <template v-if="_.get(currentUser, 'admin')">
          <v-list-item @click="moveToAdmin()">
            <v-list-item-title>管理画面</v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item @click.prevent.stop="inquiryDialog = true">
          <v-list-item-title>お問い合わせ</v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item @click="logout">
          <v-list-item-title>ログアウト</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <EditFeelingDialog v-model="editFeelingDialog" />
    <v-dialog
      v-model="inquiryDialog"
      max-width="640px"
    >
      <Inquiry />
    </v-dialog>
    <NotifySettingsDialog v-model="notifySettingsDialog" />
  </v-app-bar>
</template>
<script lang="ts">
import Vue from 'vue'
import devise from '@/utils/auth/DeviseAuthToken'
import _isNil from 'lodash/isNil'
import EditFeelingDialog from '@/components/organisms/feelings/EditFeelingDialog.vue'
import Me from '@/store/models/Me'
import Gift from '@/store/models/Gift'
import Component from 'vue-class-component'
import { Record } from '@vuex-orm/core'
import Avatar from '@/components/organisms/Avatar.vue'
import {
  mdiAccountOutline,
  mdiAccountSync,
  mdiAccountSyncOutline,
  mdiArrowLeft,
  mdiDotsVertical,
  mdiGift,
  mdiGraph,
  mdiInformationOutline,
  mdiMenu,
  mdiTrophyAward,
} from '@mdi/js'
import { User } from '@/store/models/Person'
import _get from 'lodash/get'
import Notice from '@/store/models/Notice'
import Community from '@/store/models/Community'
import Inquiry from '@/components/organisms/Inquiry.vue'
import { Emit } from 'vue-property-decorator'
import numberToString from '@/utils/numberToString'
import NotifySettingsDialog from '@/components/organisms/NotifySettingsDialog.vue'
import adminRoutes from '@/apps/admin/routes'
import _find from 'lodash/find'
import _includes from 'lodash/includes'
import { namespace } from 'vuex-class'

const NoticeDialog = namespace('noticeDialog')
@Component({
  components: {
    Inquiry,
    EditFeelingDialog,
    Avatar,
    NotifySettingsDialog,
  },
})
export default class extends Vue {
  readonly icons = {
    mdiArrowLeft,
    mdiMenu,
    mdiGift,
    mdiDotsVertical,
    mdiAccountOutline,
    mdiGraph,
    mdiInformationOutline,
    mdiTrophyAward,
    mdiAccountSync,
    mdiAccountSyncOutline,
  }
  editFeelingDialog = false
  inquiryDialog = false
  notifySettingsDialog = false
  @NoticeDialog.Action('show') showNoticeDialog
  pushManager = {
    get isSupported(): boolean {
      return 'PushManager' in window
    },
  }

  get currentUser(): Record {
    return Me.query().with(['tenant']).first()
  }

  get tenant(): Record {
    return _get(this.currentUser, 'tenant')
  }

  get communities(): Record[] {
    return Community.all()
  }

  get numberOfMembers(): number {
    return User.available().count()
  }

  get numberOfTakes(): number {
    return Gift.query()
      .where((record) => record.recipient_id === this.currentUser.id && _isNil(record.accepted_at))
      .count()
  }

  get numberOfNotices(): number {
    return Notice.query()
      .where((record) => !record.read)
      .count()
  }

  get isTimeline(): boolean {
    return this.$route.name === 'timeline'
  }

  get isAdminMode(): boolean {
    return this.$store.state.screen.mode === 'admin'
  }

  toBadgeString(value: number): string {
    return numberToString(value, 99)
  }

  @Emit('click:menu')
  clickMenu(): void {
    return void 0
  }

  async logout(): Promise<void> {
    await this.$http.delete('auth/sign_out')
    devise.logout()
  }

  showEditFeelingDialog(): void {
    this.editFeelingDialog = true
  }

  showNotifySettingsDialog(): void {
    if (!this.pushManager.isSupported) return
    this.notifySettingsDialog = true
  }

  toggleScreenMode(): void {
    if (this.$store.state.screen.mode === 'admin') {
      this.$store.dispatch('screen/switchToGeneral')
    } else {
      this.$store.dispatch('screen/switchToAdmin')
    }
  }

  moveToAdmin(path = ''): void {
    if (path === '') {
      const dashboard = _find(adminRoutes, { name: 'admin/dashboard' })
      if (_includes(dashboard.meta.unavailablePlans, this.tenant.plan)) {
        path = _find(adminRoutes, { name: 'members' }).path
      } else {
        path = dashboard.path
      }
    }
    this.$router.push({ path: `/admin/${path}` })
  }

  moveToCommunity(community): void {
    window.open(`${this.currentUser.communityHost}/${community.channel}`, '_blank', 'noopener')
  }

  mounted(): void {
    User.fetchAll({ useCache: false, available: true })
  }

  historyBack(event: MouseEvent): void {
    event.preventDefault()
    event.stopPropagation()
    this.$router.push({ name: 'timeline' })
  }
}
</script>
