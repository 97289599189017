/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
import { Collection, Fields, Item } from '@vuex-orm/core'
import Reaction from '@/store/models/Reaction'
import _isNil from 'lodash/isNil'
import _map from 'lodash/map'
import { User } from '@/store/models/Person'
import Model from '@/store/models/Model'

export default class Comment extends Model {
  static entity = 'Comment'
  static _endpoint = 'comments'
  id!: number
  body!: string
  commentable_id!: number
  commentable_type!: 'Gift' | 'Tweet'
  user_id!: number
  user!: Item<User>
  reactions: Collection<Reaction>
  edited!: boolean
  created_at: string
  updated_at: string

  static fields(): Fields {
    return {
      id: this.attr(null),
      body: this.attr(null),
      commentable_id: this.attr(null),
      commentable_type: this.attr(null),
      user_id: this.attr(null),
      user: this.belongsTo(User, 'user_id'),
      reactions: this.morphMany(Reaction, 'reactable_id', 'reactable_type'),
      edited: this.attr(false),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      files: this.attr([]),
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static create({ data }): Promise<any> {
    return this.dispatch('create', { data: this.doProc(data) })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static insert({ data }): Promise<any> {
    return this.dispatch('insert', { data: this.doProc(data) })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static update({ data }): Promise<any> {
    return this.dispatch('update', { data: this.doProc(data) })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static insertOrUpdate({ data }): Promise<any> {
    return this.dispatch('insertOrUpdate', { data: this.doProc(data) })
  }

  static doProc(data: Item<Comment> | Collection<Comment>): Item<Comment> | Collection<Comment> {
    if (_isNil(data)) return data
    return _map(Array.isArray(data) ? data : [data], (comment) => Comment.format(comment))
  }

  static format(comment: Item<Comment>): Item<Comment> {
    comment.reactions = comment.reactions.map((reaction) => Reaction.format(reaction))
    return comment
  }
}
