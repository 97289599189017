/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
import _get from 'lodash/get'
import dataLayer from '@/utils/dataLayer'
import { Record } from '@vuex-orm/core'
import http from '@/utils/http'
import moment from 'moment'
import _isNil from 'lodash/isNil'
import { Resident } from '@/store/models/Person'
import * as Sentry from '@sentry/browser'

export interface LoadOptions {
  useCache: boolean
  urlPrefix?: string
}

export default class CommunityMe extends Resident {
  static entity = 'CommunityMe'
  static _endpoint = 'communities/me'
  static baseEntity = Resident.endpoint()

  static async load(options: Partial<LoadOptions> = {}): Promise<void> {
    const useCache = !_isNil(options['useCache']) ? options['useCache'] : true
    const urlPrefix = options['urlPrefix'] || ''
    const urls = []
    if (urlPrefix) {
      urls.push(urlPrefix)
    }
    if (this.loading) {
      await this.waitLoading()
    }
    if (useCache && this.cachedTimestamp > moment().add(-1, 'minute').unix()) {
      return
    }
    this.loading = true
    const requests = [http.get([...urls, 'me'].join('/'))]
    const responses = await Promise.all(requests)
    const user = responses[0].data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = {
      ...user,
    }
    await this.insertOrUpdate({ data })
    this.loading = false
    this.cachedTimestamp = moment().unix()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static create(payload): Promise<any> {
    return this.dispatch('create', payload)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static insert(payload): Promise<any> {
    return this.dispatch('insert', payload)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static update(payload): Promise<any> {
    return this.dispatch('update', payload)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static insertOrUpdate(payload): Promise<any> {
    return this.dispatch('insertOrUpdate', payload)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static dispatch(method: string, payload?: any): Promise<any> {
    Resident.dispatch(method, payload)
    const result = super.dispatch(method, payload)
    const currentUser: Record = this.query().first()
    const email = _get(currentUser, 'email')
    dataLayer.push({
      userid: email,
      email,
    })
    Sentry.getCurrentScope().setUser({ email })
    return result
  }
}
