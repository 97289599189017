<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :value="value"
    max-width="600"
    @input="onInputDialog"
  >
    <template #activator="{ on }">
      <slot
        :on="on"
        name="activator"
      />
    </template>
    <template #default="dialog">
      <v-card>
        <v-card-title
          class="text-h5 grey lighten-2 mb-5"
          primary-title
        >
          {{ title }}
        </v-card-title>
        <slot name="top" />
        <v-card-text>
          <v-card
            elevation="0"
            outlined
            rounded
          >
            <v-card-text>
              <span
                class="p-delete-dialog__message"
                @click="onClickMessage"
                v-html="message"
              />
            </v-card-text>
          </v-card>
        </v-card-text>
        <slot name="bottom" />
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click.stop="onClickClose(dialog)"
          >
            キャンセル
          </v-btn>
          <v-btn
            :disabled="disabledDelete"
            color="error"
            text
            @click.stop="onClickDelete(dialog)"
          >
            {{ execLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import _isNil from 'lodash/isNil'

@Component
export default class extends Vue {
  @Prop({ default: '削除' })
  readonly title!: string
  @Prop({ default: false })
  readonly value!: boolean
  @Prop({ default: '' })
  readonly message!: string
  @Prop({ default: false })
  readonly disabledDelete!: boolean
  @Prop({ default: 'OK' })
  readonly execLabel!: string

  @Emit()
  input(value: boolean): boolean {
    return value
  }

  @Emit('click:hash-tag')
  clickHashTag(value: string): string {
    return value
  }

  @Emit('click:delete')
  clickDelete(): void {
    return undefined
  }

  onInputDialog(value: boolean): void {
    this.input(value)
  }

  onClickClose(dialog: { value: boolean }): void {
    dialog.value = false
  }

  onClickDelete(dialog: { value: boolean }): void {
    dialog.value = false
    this.clickDelete()
  }

  onClickMessage(event: PointerEvent): void {
    const target = event.target as HTMLElement
    const hash = target.getAttribute('data-hashTag')
    if (_isNil(hash)) return
    this.input(false)
    this.clickHashTag(hash)
  }
}
</script>

<style lang="scss" scoped>
.p-delete-dialog {
  &__message {
    word-break: break-all;
    word-wrap: break-word;
  }
}
</style>
