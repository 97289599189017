/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
import Model from '@/store/models/Model'
import { Fields } from '@vuex-orm/core'

export default class Category extends Model {
  static entity = 'Category'
  static _endpoint = 'categories'

  id!: number
  name!: string
  created_at: string
  updated_at: string
  display_order: number

  static fields(): Fields {
    return {
      id: this.attr(null),
      name: this.attr(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      display_order: this.attr(0),
    }
  }
}
