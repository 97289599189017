/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
import { Collection, Fields, Item } from '@vuex-orm/core'
import _map from 'lodash/map'
import _isNil from 'lodash/isNil'
import Model from '@/store/models/Model'
import Gift from '@/store/models/Gift'
import Tweet from '@/store/models/Tweet'

export default class Timeline extends Model {
  static entity = 'Timeline'
  static _endpoint = 'timelines'
  id!: number
  timelinable_id!: number
  timelinable_type!: 'Gift' | 'Tweet'
  timelinable: Item<Gift> | Item<Tweet>
  created_at: string
  updated_at: string

  static fields(): Fields {
    return {
      id: this.attr(null),
      timelinable_id: this.attr(null),
      timelinable_type: this.attr(null),
      timelinable: this.morphTo('timelinable_id', 'timelinable_type'),
      created_at: this.attr(null),
      updated_at: this.attr(null),
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static create({ data }): Promise<any> {
    return this.dispatch('create', { data: this.doProc(data) })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static insert({ data }): Promise<any> {
    return this.dispatch('insert', { data: this.doProc(data) })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static update({ data }): Promise<any> {
    return this.dispatch('update', { data: this.doProc(data) })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static insertOrUpdate({ data }): Promise<any> {
    return this.dispatch('insertOrUpdate', { data: this.doProc(data) })
  }

  static doProc(data: Item<Timeline> | Collection<Timeline>): Item<Timeline> | Collection<Timeline> {
    if (_isNil(data)) return data
    return _map(Array.isArray(data) ? data : [data], (timeline) => this.format(timeline))
  }

  static format(timeline: Item<Timeline>): Item<Timeline> {
    switch (timeline.timelinable_type) {
      case 'Gift':
        timeline.timelinable = Gift.format(timeline.timelinable as Item<Gift>)
        break
      case 'Tweet':
        timeline.timelinable = Tweet.format(timeline.timelinable as Item<Tweet>)
        break
    }
    timeline['timelinable_id'] = timeline.timelinable.id
    return timeline
  }
}
