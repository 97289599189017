import Vue from 'vue'
import Component from 'vue-class-component'
import ReactionChipGroup from '@/components/organisms/actions/ReactionChipGroup.vue'
import { Record } from '@vuex-orm/core'
import Me from '@/store/models/Me'
import { AxiosResponse } from 'axios'
import ReactionAction from '@/components/organisms/actions/ReactionAction.vue'
import Reaction from '@/store/models/Reaction'
import { ReactableModel } from '@/store/models'
import CommunityMe from '@/store/models/communities/Me'

@Component({
  components: {
    ReactionAction,
    ReactionChipGroup,
  },
})
export default class Reactable extends Vue {
  readonly reactableModel: ReactableModel
  showReaction = false
  reactionLoading = false

  get item(): Record {
    return undefined
  }

  get currentUser(): Record {
    return Me.query().first()
  }

  get apiPrefix(): string {
    return undefined
  }

  get reactions(): Record[] {
    return Reaction.uniq(this.item?.reactions)
  }

  async selectReaction(value): Promise<void> {
    this.reactionLoading = true
    const query = Reaction.query()
      .where('reactable_id', this.item?.id)
      .where('reactable_type', this.reactableModel.entity)
      .where('value', value)
    if (this.currentUser instanceof CommunityMe) {
      query.where('resident_id', this.currentUser['id'])
    } else {
      query.where('user_id', this.currentUser['id'])
    }
    const reactions: Record[] = query.get()
    if (reactions.length === 0) {
      await this.postReaction(value)
    } else {
      await this.deleteReaction(reactions)
    }
    this.reactionLoading = false
  }

  async postReaction(value): Promise<void> {
    const urls = []
    if (this.apiPrefix) {
      urls.push(this.apiPrefix)
    }
    urls.push(this.reactableModel.endpoint(), this.item?.id, Reaction.endpoint())
    const { data } = await this.$http.post(urls.join('/'), {
      value,
    })
    this.reactableModel.insertOrUpdate({ data })
  }

  async deleteReaction(reactions): Promise<void> {
    const urls = []
    if (this.apiPrefix) {
      urls.push(this.apiPrefix)
    }
    urls.push(Reaction.endpoint())
    const requests = reactions.map((reaction) => {
      return (
        this.$http
          .delete([urls.join('/'), reaction.id].join('/'))
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((err: any) => err)
      )
    })
    const responses: AxiosResponse[] = await Promise.all(requests)
    responses.forEach((response, index) => {
      if (response.status === 204) {
        Reaction.delete(reactions[index].id)
      }
    })
  }
}
