import { ActionTree } from 'vuex'
import { ScreenError, ScreenState } from './types'
import { RootState } from '@/store/modules/types'

const actions: ActionTree<ScreenState, RootState> = {
  switchToAdmin: async ({ commit }) => {
    commit('update', { mode: 'admin' })
  },
  switchToGeneral: async ({ commit }) => {
    commit('update', { mode: 'general' })
  },
  showError: async ({ commit }, error: ScreenError) => {
    commit('update', { error })
  },
  clear: async ({ commit }) => {
    const payload: ScreenState = {
      error: null,
      mode: 'general',
    }
    commit('update', payload)
  },
}
export default actions
