<template>
  <MyContainer>
    <MyContent>
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('label.listOfPoints') }}
        </v-card-title>
        <v-card-text>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header v-slot="{ open }">
                フィルター {{ open ? '' : `: ${conditionText}` }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row
                  class="ma-0 pa-0 mb-2"
                >
                  <v-col
                    cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                    xl="6"
                  >
                    <TextFilter
                      v-model="searchString"
                      :label="`${$t('user.name')}または${$t('user.email')}で絞り込む`"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                    xl="6"
                  >
                    <UserStatusFilter v-model="status" />
                  </v-col>
                </v-row>
                <v-row
                  class="ma-0 pa-0 mb-2"
                >
                  <v-col
                    cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                    xl="6"
                  >
                    <DepartmentFilter v-model="chosenDepartment" />
                  </v-col>
                  <v-col
                    cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                    xl="6"
                  >
                    <TagFilter v-model="chosenTags" />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <UserTable
          :headers="headers"
          :item-class="() => `cell--clickable`"
          :items="usersPerPage"
          :loading="loading || !currentUser"
          :options="options"
          @update:options="changeOptions"
          @click:row="showExchangeHistory"
        >
          <template #top>
            <ExchangeHistoryDialog
              v-model="exchange.show"
              :user-id="exchange.userId"
            />
            <v-dialog
              v-model="progressDialog"
              width="500"
            >
              <v-card>
                <v-progress-linear
                  v-model="progress"
                  color="primary"
                  height="25"
                  striped
                />
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="resetDialog"
              width="500"
            >
              <v-card>
                <v-card-title
                  class="text-h5 grey lighten-2 mb-5"
                  primary-title
                >
                  つかえるチップを初期化
                </v-card-title>
                <v-card-text>
                  つかえるチップを初期化します。<br>
                  初期化したチップは利用履歴に追加されます。<br>
                  本当によろしいですか？
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    text
                    @click.stop="resetDialog = false"
                  >
                    キャンセル
                  </v-btn>
                  <v-btn
                    color="primary"
                    text
                    @click.stop="onClickResetAllPoints"
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-toolbar flat>
              <v-spacer />
              <v-btn
                :disabled="loading"
                :loading="loading"
                @click.stop="resetDialog = true"
              >
                <v-icon left>
                  {{ icons.mdiReload }}
                </v-icon>
                つかえるチップを初期化
              </v-btn>
            </v-toolbar>
          </template>
          <template #item.tags="{ item }">
            <TagGroup :value="item.tags" />
          </template>
        </UserTable>
      </v-card>
    </MyContent>
  </MyContainer>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import TagGroup from '@/components/organisms/TagGroup.vue'
import { User, UserStatus } from '@/store/models/Person'
import { Collection, Item } from '@vuex-orm/core'
import Me from '@/store/models/Me'
import _forEach from 'lodash/forEach'
import _isNil from 'lodash/isNil'
import _map from 'lodash/map'
import * as http from '@/utils/http'
import { SearchCondition, SearchSort } from '@/utils/http'
import { mdiDelete, mdiDeleteForever, mdiDeleteRestore, mdiPencil, mdiReload } from '@mdi/js'
import { DataOptions, DataTableHeader } from 'vuetify'
import _includes from 'lodash/includes'
import { FetchResult } from '@/store/models/Model'
import { UserTag } from '@/store/models/Tag'
import Department from '@/store/models/Department'
import Tenant from '@/store/models/Tenant'
import MyDataTable from '@/components/atoms/MyDataTable.vue'
import ExchangeHistoryDialog from '@/components/organisms/exchanges/ExchangeHistoryDialog.vue'
import TagFilter from '@/components/organisms/TagFilter.vue'
import DepartmentFilter from '@/components/organisms/DepartmentFilter.vue'
import UserStatusFilter from '@/components/organisms/UserStatusFilter.vue'
import TextFilter from '@/components/organisms/TextFilter.vue'
import MyContent from '@/components/atoms/MyContent.vue'
import MyContainer from '@/components/atoms/MyContainer.vue'
import _chunk from 'lodash/chunk'
import delay from '@/utils/delay'

const UserTable = MyDataTable<User>
@Component({
  components: {
    MyContainer,
    MyContent,
    TextFilter,
    UserStatusFilter,
    DepartmentFilter,
    TagFilter,
    UserTable,
    ExchangeHistoryDialog,
    TagGroup,
  },
  metaInfo() {
    return {
      title: 'チップ一覧',
    }
  },
})
export default class extends Vue {
  readonly icons = {
    mdiPencil,
    mdiDelete,
    mdiDeleteRestore,
    mdiDeleteForever,
    mdiReload,
  }
  options: Partial<DataOptions> = {}
  loading = true
  totalCount = 0
  chosenDepartment: Item<Department> = null
  chosenTags: string[] = []
  searchString = ''
  status: 'all' | UserStatus = 'active'

  exchange: {
    show: boolean
    userId?: number
  } = {
    show: false,
  }

  resetDialog = false
  progressDialog = false
  progress = 0

  get conditionText(): string {
    const conditionTexts = []
    if (!_isNil(this.searchString) && this.searchString !== '') {
      conditionTexts.push(this.searchString)
    }
    conditionTexts.push(this.$t(this.status))
    if (!_isNil(this.chosenDepartment)) {
      conditionTexts.push(this.chosenDepartment.name)
    }
    if (!_isNil(this.chosenTags) && this.chosenTags.length > 0) {
      conditionTexts.push(this.chosenTags.join(' / '))
    }

    return conditionTexts.join(', ')
  }

  get headers() {
    const headers: DataTableHeader<Item<User>>[] = [
      {
        text: this.$t('user.name') as string,
        value: 'name',
        width: '180px',
      },
      {
        text: this.$t('user.department') as string,
        value: 'department.name',
        sortable: false,
      },
      {
        text: this.$t('user.tags') as string,
        value: 'tags',
        sortable: false,
      },
    ]
    if (this.tenant?.exchange_mode === 'drawable') {
      headers.push({
        text: this.$t('user.exchangeable') as string,
        value: 'exchangeable',
        sortable: true,
        align: 'end',
      })
    } else {
      headers.push({
        text: this.$t('user.takes') as string,
        value: 'takes',
        sortable: true,
        align: 'end',
      })
    }
    return headers
  }

  get tenant(): Item<Tenant> {
    return this.currentUser?.tenant
  }

  get currentUser(): Item<Me> {
    return Me.query().with(['tenant']).first()
  }

  get departments(): Collection<Department> {
    return Department.query().orderBy('display_order', 'asc').all()
  }

  get tags(): string[] {
    return _map(UserTag.all(), 'name')
  }

  get users(): Collection<User> {
    const { sortBy, sortDesc } = this.options
    const query = User.queryByStatus(this.status).with(['department'])
    if (!_isNil(this.searchString) && this.searchString !== '') {
      query.where((record) => {
        if (!_isNil(this.searchString) && this.searchString !== '') {
          const re = new RegExp(this.searchString.replace(/[\\^$.*+?()[\]{}|]/g, '\\$&'))
          return re.test(record.email) || re.test(record.name)
        }
        return true
      })
    }
    if (!_isNil(this.chosenDepartment)) {
      query.where('department_id', this.chosenDepartment.id)
    }
    if (!_isNil(this.chosenTags) && this.chosenTags.length > 0) {
      query.where((record) => {
        for (const chosenTag of this.chosenTags) {
          if (_includes(record.tags, chosenTag)) return true
        }
        return false
      })
    }
    query.orderBy('deleted_at', 'desc')
    _forEach(sortBy, (key, index) => {
      query.orderBy(key, sortDesc[index] ? 'desc' : 'asc')
    })
    query.orderBy('id', 'asc')
    return query.get()
  }

  get usersPerPage(): Collection<User> {
    const { page, itemsPerPage } = this.options
    if (itemsPerPage > 0) {
      return this.users.slice((page - 1) * itemsPerPage, page * itemsPerPage)
    }
    return this.users
  }

  changeOptions(value: { sortBy: string[]; sortDesc: boolean[]; page: number; itemsPerPage: number }): void {
    this.options = value
    this.loading = true
    this.fetch().finally(() => {
      this.loading = false
    })
  }

  async fetch(): Promise<void> {
    await Promise.all([UserTag.fetchAll({ useCache: false }), Department.fetchAll({ useCache: false })])
    const { sortBy, sortDesc, page, itemsPerPage } = this.options
    const limit = itemsPerPage > 0 ? itemsPerPage : 25
    const sort: SearchSort = [{ name: 'deleted_at', order: 'desc' }]
    sortBy.forEach((key, index) => {
      sort.push({ name: key, order: sortDesc[index] ? 'desc' : 'asc' })
    })
    sort.push({ name: 'id', order: 'asc' })
    const params = <Partial<http.RansackParams>>Object.assign(
      {},
      new SearchCondition({ page, limit, sort }).toRansackParams(),
      User.createSearchCondition({
        searchString: this.searchString,
        departmentId: this.chosenDepartment?.id,
        tags: this.chosenTags,
        status: this.status,
      }),
    )
    let result: FetchResult
    if (itemsPerPage === -1) {
      result = await User.fetchAll({ useCache: false, params })
    } else {
      result = await User.fetch({ useCache: false, params })
    }
    this.totalCount = result.totalCount
  }

  async mounted(): Promise<void> {
    await Promise.all([UserTag.fetchAll({ useCache: false }), Department.fetchAll({ useCache: false })])
    await Me.load()
  }

  async showExchangeHistory(user: Item<User>): Promise<void> {
    this.exchange.userId = user?.id
    this.exchange.show = true
  }

  async onClickResetAllPoints() {
    this.loading = true
    this.resetDialog = false
    this.progress = 0
    this.progressDialog = true
    await this.resetAllPoints()
    this.loading = false
    await delay(500)
    this.progressDialog = false
    this.fetch()
  }

  async resetAllPoints() {
    const params = {
      'with_deleted': true,
      'with_unaccepted': true,
      'with_unapproved': true,
    }
    await User.fetchAll({ useCache: false, params })
    const users = User.all()
    const callExchanges = _chunk(users
      .filter(({ exchangeable }) => exchangeable > 0)
      .map(({ id, exchangeable }) => (this.$http.post(`users/${id}/exchanges`, {
        memo: `初期化`,
        point: exchangeable,
      }))), 3)
    const unit = 100 / (callExchanges.length + 1)
    for (const chunk of callExchanges) {
      await Promise.all(chunk)
      this.progress += unit
    }
    this.progress = 100
  }
}
</script>

